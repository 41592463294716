import MKBox from "components/MKBox";
import React, { useState } from "react";
import routes from "routes";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import bg from "../../../assets/bookingpage/BG2.svg";
import { FileSVG, Plane } from "./SVGS";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import Slider from "react-slick";
import deal from "../../../assets/bookingpage/deal.svg";
import oxfard from "../../../assets/bookingpage/oxford.png";
import quotes from "../../../assets/bookingpage/Vector.png";
import quotesClose from "../../../assets/bookingpage/Vector (1).png";
import review1 from "../../../assets/bookingpage/Screenshot (13).png";
import search from "../../../assets/bookingpage/search.svg";
import puzzle from "../../../assets/bookingpage/puzzle.svg";
import books from "../../../assets/bookingpage/books.svg";
import dart from "../../../assets/bookingpage/dart 1.png";
import duke from "../../../assets/svgs/duke 1.svg";
import stanford from "../../../assets/bookingpage/Ellipse 2.png";
import warwick from "../../../assets/bookingpage/warwick 1.png";
import review2 from "../../../assets/bookingpage/reviews/Screenshot (13) (1).png";
import review3 from "../../../assets/bookingpage/reviews/Screenshot (14).png";
import review4 from "../../../assets/bookingpage/reviews/Screenshot (15).png";
import review5 from "../../../assets/bookingpage/reviews/Screenshot (35) (1).png";
import review6 from "../../../assets/bookingpage/reviews/Screenshot (35).png";
import review7 from "../../../assets/bookingpage/reviews/Screenshot (36).png";
import review8 from "../../../assets/bookingpage/reviews/Screenshot (36) (1).png";
import review9 from "../../../assets/bookingpage/reviews/Screenshot (70).png";
import confirm from "../../../assets/bookingpage/confirm 1.png";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Link, useNavigate } from "react-router-dom";
import { sendConfimationEmail } from "./SendEmail";
import toast from "react-hot-toast";
import bgBooking from "../../../assets/bookingpage/bookBg.png";
import * as z from "zod";
const bookingSchema = z.object({
  name: z.string().min(1, "Name is required"),
  email: z.string().email("Invalid email address"),
  targetDegree: z.string().min(1, "Degree is required"),
  number: z
    .string()
    .regex(/^\d+$/, "Number must contain only digits")
    .min(10, "Number must be at least 10 digits"),
  date: z.string(),
});
export const BookingPage = () => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(dayjs());
  // const [open, setOpen] = React.useState(false);
  const [openBooking, setOpenBooking] = React.useState(false);
  const [data, setData] = useState({
    name: "",
    email: "",
    degree: "",
    number: "",
  });
  const handleChange = (e) => {
    const { value, name } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleOpen = async () => {
    toast.loading("Loading..", {
      id: 1,
    });
    try {
      const parsedData = bookingSchema.parse({
        ...data,
        targetDegree: data.degree,
        date: value.format(),
      });
      const res = await fetch("https://al-connect-production.up.railway.app/bookingpage", {
        method: "POST",
        body: JSON.stringify(parsedData),
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!res.ok) {
        toast.error("Something went wrong please try again", {
          id: 1,
        });
      } else {
        await sendConfimationEmail({ name: data.name, email: data.email });
        toast.success("Your Appointment has been Booked", {
          id: 1,
        });
        navigate("/confirmation");
      }
    } catch (e) {
      if (e instanceof z.ZodError) {
        e.errors.forEach((error) =>
          toast.error(error.message, {
            id: 1,
          })
        );
      } else {
        console.log(e);
      }
    }
  };
  // const handleClose = () => setOpen(false);
  const handleCloseBooking = () => setOpenBooking(false);
  const handleOpenBooking = () => setOpenBooking(true);
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 350,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const styleBooking = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
    p: 4,
    backgroundImage: `url(${bgBooking})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  return (
    <div className=" bg-white min-w-full">
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-react",
          label: "free download",
          color: "default",
        }}
      />
      <MKBox pt={{ lg: "85px", md: "65px", xs: "65px" }} className=" w-full">
        <div className=" text-[#0C2343] md:tracking-widest text-[30px] md:text-[40px] font-extrabold md:leading-[97.5px] text-center ml-5">
          NO COOKIE CUTTER ADVICES HERE
        </div>
        <div className=" flex  my-5 justify-center items-center w-full p-5 md:p-0">
          <Plane />
          <div className=" text-[#0C2343] text-xl ml-3 md:ml-0">
            A <span className="text-[#FD5523]">100%</span> guarantee that you will love this session
          </div>
          <FileSVG />
        </div>
        <Modal
          open={openBooking}
          onClose={handleCloseBooking}
          ariaLabelledby="modal-modal-title"
          ariaDescribedby="modal-modal-description"
        >
          <Box sx={styleBooking}>
            <div className=" w-[600px] h-[600px] flex justify-center items-center">
              <div className=" flex flex-col justify-center items-center mt-5 gap-3">
                <div className="text-2xl font-extrabold">Enter Your Details</div>
                <div className=" w-full p-3">
                  <div className=" font-bold">Name</div>
                  <input
                    name="name"
                    onChange={handleChange}
                    type="text"
                    className=" rounded-md w-full border-2"
                  />
                  <div className=" font-bold">Email</div>
                  <input
                    type="text"
                    name="email"
                    onChange={handleChange}
                    className="rounded-md w-full border-2"
                  />
                  <div className=" font-bold">Target Degree</div>
                  <input
                    type="text"
                    name="degree"
                    onChange={handleChange}
                    className="rounded-md w-full border-2"
                  />
                  <div className=" font-bold">Number</div>
                  <input
                    type="text"
                    name="number"
                    onChange={handleChange}
                    className="rounded-md w-full border-2"
                  />
                  <div className=" flex justify-center items-center mt-2 p-3 md:p-0">
                    <button
                      onClick={handleOpen}
                      className="text-center bg-[#FD5523] text-white text-xl rounded-full py-3 font-bold px-2"
                    >
                      Lets Connect Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Modal>

        <div className=" w-full md:h-[600px] mb-10 relative">
          <div
            className=" md:w-full md:h-full flex items-center justify-center"
            style={{
              backgroundImage: `url(${bg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className=" flex flex-col justify-center mx-20 p-3 md:mx-10">
              <div className="ml-3 flex flex-col justify-center shadow-2xl min-h-fit min-w-fit  md:h-[500px] md:w-[700px] bg-[#ffff] border-2 rounded-3xl md:px-5 pb-5">
                <div className="text-[#0C2343] font-bold text-[20px] md:text-[23px]  mt-2 md:mx-0 md:mt-0 px-3">
                  Why this <span className="text-[#FD5523]">strategy</span> session?
                </div>
                <div className=" ml-3 flex flex-col md:flex-row justify-center">
                  <div className=" md:w-[49%] mt-5">
                    <div className=" flex flex-col justify-center items-center gap-3">
                      <div className=" flex items-center">
                        <div className="md:w-[55px] ">
                          <img src={deal} className=" w-[55px] h-[60px]" />
                        </div>
                        <div className=" w-full text-[16px] text-[#0C2343]">
                          Analysis of your Strengths and weaknesses.
                        </div>
                      </div>
                      <div className=" flex items-center w-full gap-2 md:gap-0 ml-2">
                        <div className="md:w-[60px]">
                          <img src={puzzle} className=" w-[35px] h-[50px]" />
                        </div>
                        <div className="text-[16px] text-[#0C2343] w-full">
                          Strategy to improve your profile.
                        </div>
                      </div>
                      <div className=" flex items-center w-full gap-2 md:gap-0 ml-2">
                        <div className="md:w-[60px]">
                          <img src={books} className=" w-[35px] h-[50px]" />
                        </div>
                        <div className=" text-[16px] text-[#0C2343] w-full mr-3">
                          Scholarship plan and amount.
                        </div>
                      </div>
                      <div className=" flex items-center w-full gap-2 md:gap-0 ml-2">
                        <div className="md:w-[60px] flex justify-start">
                          <img src={search} className=" w-[35px] h-[50px]" />
                        </div>
                        <div className=" text-[16px] text-[#0C2343] w-full">
                          Personalized college list.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" w-full md:w-[2px] bg-slate-300 md:h-[72%] h-[3px]  mt-14 mx-1"></div>
                  <div className=" md:w-[50%]">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DateCalendar", "DateCalendar"]}>
                        <DemoItem>
                          <DateCalendar value={value} onChange={(newValue) => setValue(newValue)} />
                        </DemoItem>
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                </div>
                <div className=" border-b-4 md:border-b-0 flex justify-center items-center mt-2 p-3 md:p-0">
                  <div
                    onClick={() => handleOpenBooking()}
                    className=" cursor-pointer hidden md:block text-center bg-[#FD5523] md:w-[70%] text-white text-xl rounded-full py-3 font-bold px-2"
                  >
                    Take Me To The Best College Now
                  </div>
                </div>
                <div className=" md:hidden flex flex-col justify-center items-center mt-5 gap-3">
                  <div className="text-2xl font-extrabold">Enter Your Details</div>
                  <div className=" w-full p-3">
                    <div className=" font-bold">Name</div>
                    <input
                      type="text"
                      name="name"
                      onChange={handleChange}
                      className=" rounded-md w-full border-2"
                    />
                    <div className=" font-bold">Email</div>
                    <input
                      type="text"
                      name="email"
                      onChange={handleChange}
                      className="rounded-md w-full border-2"
                    />
                    <div className=" font-bold">Target Degree</div>
                    <input
                      type="text"
                      name="degree"
                      onChange={handleChange}
                      className="rounded-md w-full border-2"
                    />
                    <div className=" font-bold">Number</div>
                    <input
                      type="text"
                      name="number"
                      onChange={handleChange}
                      className="rounded-md w-full border-2"
                    />
                    <div className=" flex justify-center items-center mt-2 p-3 md:p-0">
                      <button
                        onClick={handleOpen}
                        className="text-center bg-[#FD5523] md:w-[70%] text-white text-xl rounded-full py-3 font-bold px-2"
                      >
                        Lets Connect Now
                      </button>
                      {/* <Modal
                        open={open}
                        onClose={handleClose}
                        ariaLabelledby="modal-modal-title"
                        ariaDescribedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <Typography
                            id="modal-modal-title"
                            variant="h5"
                            component="h2"
                            className=" text-2xl"
                          >
                            Your Appointment has been booked.
                            <div className=" w-full flex justify-center p-3">
                              <img src={confirm} height={70} width={70} />
                            </div>
                          </Typography>
                          <Typography id="modal-modal-description" className="" sx={{ mt: 2 }}>
                            Kindly Check your email for the confirmation
                            <div className=" w-full h-[10px] bg-orange-500"></div>
                          </Typography>
                        </Box>
                      </Modal> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" mt-8">
          <div className=" text-[35px] text-[#0C2343] text-center font-bold p-5">
            We have admits in
          </div>
          <AllUniversity settings={settings} />
        </div>
        <div className=" mt-16">
          <h1 className=" text-[33px] md:text-[40px] text-[#0C2343] text-center font-extrabold">
            WHY <span className="text-[#FD5523]">Al-CONNECT?</span>
          </h1>
          <div className=" mt-5 text-[#0C2343] text-center font-extrabold text-3xl">Ask Them.</div>
          <div className=" p-2 grid md:grid-cols-2 bg-[#ffa88e]   md:my-10 md:p-10 gap-x-5 gap-y-5 rounded-2xl w-full">
            {CardData.map((review) => (
              <Card review={review} />
            ))}
          </div>
        </div>
      </MKBox>
    </div>
  );
};
const AllUniversity = ({ settings }) => {
  return (
    <div className="slider w-full p-10 bg-white">
      <Slider {...settings} className=" bg-white gap-2">
        {University.map((uni, index) => {
          return (
            <div
              key={index}
              className="sliderDiv border-2 flex justify-center h-[330px] w-[100px] items-center gap-3"
            >
              <img key={index} src={uni} className=" w-full h-full" />
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

const Card = ({ review }) => {
  return (
    <div className=" md:p-5 flex flex-col items-center w-full bg-white rounded-2xl shadow-2xl">
      <div className=" flex justify-start  w-full md:ml-5">
        <img src={quotes} className=" h-[20px] w-[30px] md:h-[30px] md:w-[50px]" />
      </div>
      <img src={review} className=" w-full h-full" />
      <div className=" flex justify-end  w-full md:mr-5">
        <img src={quotesClose} className=" h-[20px] w-[30px]  md:h-[30px] md:w-[50px]" />
      </div>
    </div>
  );
};

const CardData = [review1, review3, review4, review6, review7, review9];

const University = [oxfard, dart, duke, stanford, warwick];
