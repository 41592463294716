import Insight1 from "assets/images/Insight1.png";
import Insight2 from "assets/images/Insight2.png";
import Insight3 from "assets/images/Insight3.png";
import Insight4 from "assets/images/Insight4.png";
import Blog1 from "assets/images/blog1.jpg";
import Blog2 from "assets/images/blog2.jpg";
import Blog3 from "assets/images/blog3.png";
import Blog4 from "assets/images/blog4.jpg";
import Blog5 from "assets/images/blog5.webp";
import Slider from "react-slick";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Link } from "react-router-dom";
export const Insights = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <MKBox component="section" py={3} className="section9  w-screen">
      <Container>
        <Grid
          style={{ textAlign: "center", justifyContent: "center" }}
          container
          xs={12}
          sm={12}
          md={12}
          xl={12}
          lg={12}
        >
          <MKTypography
            variant="text"
            style={{ color: "#2B3F5B", fontFamily: "Poppins", width: "100%" }}
            px={{ xs: 2, lg: 0 }}
            className="responsiveHeading"
            mt={1}
            mb={2}
            textAlign={{ lg: "center", xs: "center" }}
          >
            <b>
              Must Read <span style={{ color: "#FD5523" }}>Insights!!</span>
            </b>
          </MKTypography>
          <div className={"slider p-4  w-screen"}>
            <Slider {...settings} className="m-4 custom-slider">
              <SingleInsight
                image={Blog1}
                tag={"Master's"}
                title={"Best Universities in Ireland For Masters"}
                content={
                  "Why should I choose Ireland for my Master’s? Ireland, known for its rich  "
                }
                link={"https://al-connect.in/blog/best-universities-in-ireland-for-masters/"}
              />
              <SingleInsight
                image={Blog5}
                tag={"Study Abroad"}
                title={"MiM Battle: ESCP vs. ESSEC"}
                content={"Deciding to pursue a Masters in Management (MiM) is a pivotal step in a"}
                link={"https://al-connect.in/blog/escp-vs-essec-mim-comparison/"}
              />
              <SingleInsight
                image={Blog4}
                tag={"Master's"}
                title={"Complete Guide to Erasmus Mundus Masters Programs"}
                content={"How to prepare for the Erasmus Mundus Masters scholarship? Have you "}
                link={
                  "https://al-connect.in/blog/complete-guide-to-erasmus-mundus-masters-programs/"
                }
              />
              <SingleInsight
                image={Blog3}
                tag={"Study Abroad"}
                title={"Top 10 Engineering Universities in the World"}
                content={
                  "What makes a university stand out in the world of engineering? In today’s"
                }
                link={"https://al-connect.in/blog/top-10-engineering-universities-in-the-world/"}
              />
              <SingleInsight
                image={Blog2}
                tag={"Master's"}
                title={"Best Universities in Australia for MBA"}
                content={
                  "Australia is a leading destination for MBA aspirants, thanks to its globally "
                }
                link={"https://al-connect.in/blog/best-universities-in-australia-for-mba/"}
              />
            </Slider>
          </div>
        </Grid>
      </Container>
    </MKBox>
  );
};
const SingleInsight = ({ image, tag, content, title, link }) => {
  return (
    <Link
      to={link}
      className=" max-w-[350px] h-[400px] border-4  p-2 rounded-md "
      onClick={() => {}}
    >
      <div
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className=" w-full h-48"
      ></div>
      <div className=" text-left text-lg text-black font-bold mt-2">{title}</div>
      <div className=" text-left">
        <button className=" text-[#FD5523] text-sm bg-slate-200 rounded-sm py-1 px-1">{tag}</button>
        <div className=" font-medium text-base">{content}</div>{" "}
        <div className=" text-slate-400 text-sm flex mt-3 gap-2 absolute bottom-0">
          <div>Learn More</div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
            />
          </svg>
        </div>
      </div>
    </Link>
  );
};
