import texas from "../../../../assets/images/bgimages/texas.jpg";
import erasmus from "../../../../assets/images/bgimages/erasmas.jpg";
import uniofsyd from "../../../../assets/images/bgimages/uniofsyd.jpg";
import imperial from "../../../../assets/images/bgimages/imperial.webp";
import utd from "../../../../assets/images/bgimages/utd.jpg";
import escade from "../../../../assets/images/bgimages/escade.jpg";
export const BGImages = {
  "Mentor 1": texas,
  "Mentor 2": erasmus,
  "Mentor 3": uniofsyd,
  "Mentor 4": imperial,
  "Mentor 5": utd,
  "Mentor 6": texas,
  "Mentor 7": erasmus,
  "Mentor 8": escade,
};
