/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKPagination from "components/MKPagination";
// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Consultants page sections
// import Profile from "pages/LandingPages/Consultants/sections/Profile";
import Posts from "pages/LandingPages/Consultants/sections/Posts";
// import Contact from "pages/LandingPages/Consultants/sections/Contact";
import Footer from "pages/LandingPages/Consultants/sections/Footer";

// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/consultantsDesktopBG.png";
import { useState } from "react";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import Container from "assets/theme/components/container";
import Grid from "@mui/material/Grid";
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";

function Consultants() {
  const [pages, setPages] = useState(1);
  const cardsPerPage = 5;
  const handlePagination = (dataLength) => {
    let noOfPages = dataLength / 5;
    setPages(noOfPages);
  };
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-react",
          label: "free download",
          color: "default",
        }}
      />
      {/* <MKBox className=" pt-28 text-xl text-center">Coming soon...</MKBox> */}
      <MKBox bgColor="white">
        <MKBox pt={{ lg: "85px", md: "65px", xs: "65px" }}>
          <MKBox
            minHeight="75vh"
            width="100%"
            sx={{
              backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                `${linearGradient(
                  rgba(gradients.dark.main, 0.6),
                  rgba(gradients.dark.state, 0.6)
                )}, url(${bgImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: "grid",
              placeItems: "center",
            }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Meet Our Experts
            </MKTypography>
          </MKBox>
        </MKBox>

        <Posts callBack={handlePagination} />
        <MKBox textAlign="center" py={4} display="flex" justifyContent="center">
          <MKPagination>
            <MKPagination item>
              <Icon>keyboard_arrow_left</Icon>
            </MKPagination>
            <MKPagination item active>
              1
            </MKPagination>
            {() => {
              let totalPages = pages;
              let btns = Array();
              for (let i = 0; i < totalPages - 1; i++) {
                btns.push(i);
              }
              btns.map((i) => {
                return <MKPagination item>{i}</MKPagination>;
              });
            }}
            <MKPagination item>
              <Icon>keyboard_arrow_right</Icon>
            </MKPagination>
          </MKPagination>
        </MKBox>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Consultants;
