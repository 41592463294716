/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import shareLocation from "assets/svgs/share-location.svg";
import hat from "assets/svgs/Path 43586.svg";
import briefcase from "assets/svgs/brifcase.svg";
import calendar from "assets/svgs/Path 43487.svg";
import post2 from "assets/images/examples/testimonial-6-3.jpg";
function ConsultantCard({
  image,
  name,
  position,
  description,
  experience,
  price,
  services,
  placeOfOrigin,
}) {
  return (
    <Card sx={{ mt: 3 }}>
      <Grid container>
        <Grid item xs={12} md={12} lg={12} sx={{ mt: -6 }}>
          <MKBox width="100%" pt={2} pb={1} px={2} position={"relative"}>
            <MKBox
              style={{
                background: `url(${image})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
              alt={name}
              width="100%"
              maxHeight="300px"
              minHeight="300px"
              borderRadius="md"
              shadow="lg"
            />
          </MKBox>
        </Grid>
        <Grid item xs={12} md={12} lg={12} sx={{ my: "auto" }}>
          <MKBox pt={0} pb={2.5} pr={2} pl={{ xs: 2, lg: 2 }} lineHeight={1}>
            <MKTypography variant="h5" sx={{ color: "#0C2343" }}>
              {name}
            </MKTypography>
            <MKTypography
              variant="body2"
              sx={{ color: "#0C2343" }}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "6px",
              }}
            >
              <img src={shareLocation} width={16}></img> {placeOfOrigin}
            </MKTypography>
            <MKTypography
              variant="body2"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "6px",
              }}
            >
              <img src={hat}></img>{" "}
              <span
                style={{
                  maxWidth: "120px",
                  display: "inline-block",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {position.course}
              </span>
              , {position.country}
            </MKTypography>
            <MKTypography
              variant="body2"
              mb={1}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "6px",
              }}
            >
              <img src={briefcase}></img> {experience ?? 10} Years of Experience
            </MKTypography>
            <MKTypography variant="body2" color="text">
              Services Offered
            </MKTypography>
            <MKBox className="tagsContainer">
              {services ? (
                services.map((service) => <MKBox className="tags">{service}</MKBox>)
              ) : (
                <>
                  <MKBox className="tags">SOP</MKBox>
                  <MKBox className="tags">Resume Building</MKBox>
                </>
              )}
            </MKBox>
            <MKBox
              sx={{
                width: "100%",
                display: "flex",
              }}
            >
              <MKButton
                to={"/pages/authentication/sign-in"}
                variant={"gradient"}
                color={"info"}
                size="small"
                style={{
                  textTransform: "capitalize",
                  display: "block",
                  marginTop: "12px",
                  flexBasis: "100%",
                  padding: "16px",
                  fontSize: "16px",
                }}
              >
                {"Book A Session"}
              </MKButton>
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
    </Card>
  );
}

// Typechecking props for the ConsultantCard
ConsultantCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
    label: PropTypes.string,
    country: PropTypes.string.isRequired,
    course: PropTypes.string.isRequired,
  }).isRequired,
  description: PropTypes.string.isRequired,
};

export default ConsultantCard;
