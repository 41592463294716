/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import team3 from "assets/images/ivana-squares.jpg";
import team4 from "assets/images/ivana-square.jpg";
import step4 from "assets/svgs/No strings attached.svg";
import img1 from "assets/images/Affordable Access.svg";
import img2 from "assets/images/Personalized Matches.svg";
import img3 from "assets/images/Direct and Honest Insights.svg";
import img4 from "assets/images/Simplified Process.svg";

function Team() {
  return (
    <MKBox
      component="section"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
      style={{
        background: "#fff",
      }}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={12} lg={12} sx={{ mb: 6 }}>
            <MKTypography variant="h3" textAlign="center">
              Why <span style={{ color: "#FD5523" }}>Choose Us</span>
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox
              style={{ backgroundColor: "#FCFCFC" }}
              lg={3}
              className={"stepBox"}
              sx={{
                p: 2,
                borderRadius: "4px",
                filter: "drop-shadow(0px 1px 1px rgba(0,0,0,0.35 ))",
                textAlign: "center",
                m: 1,
                height: "100%",
              }}
            >
              <MKBox height={"55px"} className=" mb-3">
                <img height={"100%"} width={"55px"} src={img1}></img>
              </MKBox>
              <MKTypography
                variant="p"
                display="block"
                fontSize={"18px"}
                fontWeight={"bold"}
                mt={"8px"}
                style={{ color: "#FD5523" }}
              >
                Affordable Access
              </MKTypography>
              <MKTypography
                variant="text"
                fontSize="14px"
                style={{ color: "#0C2343", maxWidth: "350px", fontFamily: "Noto Sans" }}
              >
                We offer a highly cost-effective alternative to traditional consulting, granting
                access to quality mentorship without the need for a hefty price tag.
              </MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox
              style={{ backgroundColor: "#FCFCFC" }}
              lg={3}
              className={"stepBox"}
              sx={{
                p: 2,
                borderRadius: "4px",
                filter: "drop-shadow(0px 1px 1px rgba(0,0,0,0.35 ))",
                textAlign: "center",
                m: 1,
                height: "100%",
              }}
            >
              <MKBox height={"65px"}>
                <img height={"100%"} width={"65px"} src={img2}></img>
              </MKBox>
              <MKTypography
                variant="p"
                display="block"
                fontSize={"18px"}
                fontWeight={"bold"}
                mt={"8px"}
                style={{ color: "#FD5523" }}
              >
                Personalized Matches
              </MKTypography>
              <MKTypography
                variant="text"
                fontSize="14px"
                style={{ color: "#0C2343", maxWidth: "350px", fontFamily: "Noto Sans" }}
              >
                With our platform, you're not randomly assigned an advisor; instead, you can choose
                a mentor aligned with your academic and professional goals, backed by real
                experience and credentials.
              </MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox
              style={{ backgroundColor: "#FCFCFC" }}
              lg={3}
              className={"stepBox"}
              sx={{
                p: 2,
                borderRadius: "4px",
                filter: "drop-shadow(0px 1px 1px rgba(0,0,0,0.35 ))",
                textAlign: "center",
                m: 1,
                height: "100%",
              }}
            >
              <MKBox height={"65px"}>
                <img height={"100%"} width={"65px"} src={img3}></img>
              </MKBox>
              <MKTypography
                variant="p"
                display="block"
                fontSize={"18px"}
                fontWeight={"bold"}
                mt={"8px"}
                style={{ color: "#FD5523" }}
              >
                Direct and Honest Insights
              </MKTypography>
              <MKTypography
                variant="text"
                fontSize="14px"
                style={{ color: "#0C2343", maxWidth: "350px", fontFamily: "Noto Sans" }}
              >
                Our mentors are committed to providing unfiltered advice and genuine insights, drawn
                from their own experiences in navigating the challenges and triumphs of studying
                abroad.
              </MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox
              style={{ backgroundColor: "#FCFCFC" }}
              lg={3}
              className={"stepBox"}
              sx={{
                p: 2,
                borderRadius: "4px",
                filter: "drop-shadow(0px 1px 1px rgba(0,0,0,0.35 ))",
                textAlign: "center",
                m: 1,
                height: "100%",
              }}
            >
              <MKBox height={"65px"}>
                <img height={"100%"} width={"65px"} src={img4}></img>
              </MKBox>
              <MKTypography
                variant="p"
                display="block"
                fontSize={"18px"}
                fontWeight={"bold"}
                mt={"8px"}
                style={{ color: "#FD5523" }}
              >
                Simplified Process
              </MKTypography>
              <MKTypography
                variant="text"
                fontSize="14px"
                style={{ color: "#0C2343", maxWidth: "350px", fontFamily: "Noto Sans" }}
              >
                From SOPs to LORs and interview prep, our mentors are here to guide you every step
                of the way, simplifying complex processes and enhancing your application's
                potential.
              </MKTypography>
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
