/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

//uni logos
import logo1 from "../../assets/images/logos/uni-logos/Auckland-University-Logo.png";
import logo2 from "../../assets/images/logos/uni-logos/Brown-Unversity-Logo.png";
import logo3 from "../../assets/images/logos/uni-logos/Cairo-University-Logo.png";
import logo4 from "../../assets/images/logos/uni-logos/Chicago-University-Logo.png";
import logo5 from "../../assets/images/logos/uni-logos/Columbia-University-Logo.png";
import logo6 from "../../assets/images/logos/uni-logos/Cornell-University-Logo.png";
import logo7 from "../../assets/images/logos/uni-logos/Duke-University-Logo.png";
import logo8 from "../../assets/images/logos/uni-logos/Harvard-University-Logo.png";
import logo9 from "../../assets/images/logos/uni-logos/Melbourne-University-Logo.png";
import mainBg from "../../assets/svgs/Desktop Updated Background.svg";
import mobileBg from "../../assets/svgs/Hero Image.svg";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

//ticker
import Ticker from "react-ticker";

// Presentation page sections
import Counters from "pages/Presentation/sections/Counters";
import Information from "pages/Presentation/sections/Information";
import DesignBlocks from "pages/Presentation/sections/DesignBlocks";
import Pages from "pages/Presentation/sections/Pages";
import Testimonials from "pages/Presentation/sections/Testimonials";
import Download from "pages/Presentation/sections/Download";

// Presentation page components
import BuiltByDevelopers from "pages/Presentation/components/BuiltByDevelopers";

import Section2 from "./sections/Section2";
import Section4 from "./sections/Section4";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/above-the-fold.jpg";
import Section3 from "./sections/Section3";
import Section5 from "./sections/Section5";
import Section6 from "./sections/Section6";
import Section7 from "./sections/Section7";
import Section8 from "./sections/Section8";
import Section9 from "./sections/Section9";
import { Link } from "react-router-dom";

function Presentation() {
  let windowWidth = window.innerWidth;
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-react",
          label: "free download",
          color: "info",
        }}
        sticky
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: { lg: `url("${mainBg}")`, sm: "none" },
          backgroundSize: "cover",
          backgroundPosition: "right 0px top 85px;",
          display: "grid",
          placeItems: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container>
          <Grid container xs={12} sm={12} md={12} xl={12} lg={12} my={{ xs: 10 }} mt={"48px"}>
            <Grid container item xs={12} lg={6} justifyContent="left" alignItems="center" mx="auto">
              <MKTypography
                variant="text"
                style={{ color: "#2B3F5B", fontFamily: "Poppins" }}
                px={{ xs: 2, lg: 0 }}
                className="responsiveHeading"
                mt={1}
                textAlign={{ lg: "left", xs: "center" }}
              >
                <b>Your College, Your Mentor</b>
                <MKTypography
                  className="responsiveSubHeading"
                  textAlign={{ lg: "left", xs: "center" }}
                >
                  Personalized Guidance to Success
                </MKTypography>
                <MKTypography
                  fontSize={"18px"}
                  style={{ color: "grey", fontFamily: "Noto Sans" }}
                  textAlign={{ lg: "left", xs: "center" }}
                  mt={1}
                >
                  We will connect you to real mentors who themselves cracked the college you are
                  targetting.
                </MKTypography>
                {windowWidth > 768 && (
                  <Link
                    to={"/pages/landing-pages/consultants"}
                    style={{
                      marginTop: "36px",
                    }}
                    className="primary-btn"
                  >
                    Find Your Ideal Mentor
                  </Link>
                )}
              </MKTypography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              lg={6}
              justifyContent="right"
              mx="auto"
              mt={{ xs: "20px" }}
            >
              <MKBox sx={{ display: { lg: "none", sm: "block" } }}>
                <img src={mobileBg} width={"100%"}></img>
              </MKBox>
            </Grid>
            {windowWidth < 768 && (
              <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                <button
                  style={{
                    marginTop: "36px",
                  }}
                  className="primary-btn-mb"
                >
                  Find Your Ideal Mentor
                </button>
              </div>
            )}
          </Grid>
        </Container>
      </MKBox>
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 windowWidth={windowWidth} />
      <Section6 />
      <Section7 windowWidth={windowWidth} />
      <Section8 />
      <Section9 />
      <MKBox pt={6} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;
