/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKPagination from "components/MKPagination";
// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Consultants page sections
import Footer from "pages/LandingPages/Consultants/sections/Footer";

// Routes
import routes from "routes";
import "../../styles/commonStyles.css";

// Images
import bgImage from "assets/images/city-profile.jpg";
import { useEffect, useState } from "react";
import MKTypography from "components/MKTypography";

function Blog() {
  const [content, setContent] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [title, setTitle] = useState(false);
  const queryParameters = new URLSearchParams(window.location.search);
  const slug = queryParameters.get("slug");
  useEffect(() => {
    fetch("https://al-connect-production.up.railway.app/blogs/" + slug, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setContent(res.data[0].content);
        setTitle(res.data[0].title);
        setIsLoaded(true);
      });
  }, []);
  if (0) {
    return <div>Error:</div>;
  } else if (!isLoaded) {
    return (
      <lottie-player
        src="images/Loader.json"
        background="transparent"
        speed="1"
        style={{ width: "300px", height: "100%" }}
        loop
        autoplay
      ></lottie-player>
    );
  } else {
    return (
      <>
        <DefaultNavbar
          routes={routes}
          action={{
            type: "external",
            route: "https://www.creative-tim.com/product/material-kit-react",
            label: "free download",
            color: "info",
          }}
          transparent
          light
        />
        <MKBox bgColor="white">
          <MKBox
            minHeight="25rem"
            width="100%"
            textAlign="center"
            sx={{
              backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                `${linearGradient(
                  rgba(gradients.dark.main, 0.8),
                  rgba(gradients.dark.state, 0.8)
                )}, url(${bgImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: "grid",
              placeItems: "center",
            }}
          >
            <MKTypography class="blogTitle" variant="h3">
              {title}
            </MKTypography>
          </MKBox>
          <Card
            sx={{
              p: 2,
              mx: { xs: 2, lg: 3 },
              mt: -8,
              mb: 4,
              backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
                rgba(white.main, 0.8),
              backdropFilter: "saturate(200%) blur(30px)",
              boxShadow: ({ boxShadows: { xxl } }) => xxl,
            }}
          >
            <MKBox
              class="blogContent"
              p="30px 25px"
              dangerouslySetInnerHTML={{ __html: content }}
            ></MKBox>
          </Card>
          <Footer />
        </MKBox>
      </>
    );
  }
}

export default Blog;
