import { useEffect } from "react";

export const SpeakWithExperts = () => {
  useEffect(() => {
    if (window.location.href.includes("speak-with-experts")) {
      document.getElementsByClassName("tidycal-embed")[0].style.display = "block";
    }
    return () => {
      document.getElementsByClassName("tidycal-embed")[0].style.display = "none";
    };
  }, []);
  return <></>;
};
