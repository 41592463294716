/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React components
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";
import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";
import ConsultantCard from "examples/Cards/TeamCards/ConsultantCard";

// Images
import post1 from "assets/images/examples/testimonial-6-2.jpg";
import post2 from "assets/images/examples/testimonial-6-3.jpg";
import post3 from "assets/images/examples/blog-9-4.jpg";
import post4 from "assets/images/examples/blog2.jpg";

import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import MKButton from "components/MKButton";
import { Box, Button, Modal, Skeleton, Typography } from "@mui/material";
import { useQuery } from "react-query";

function Posts(props) {
  // const [consultants, setConsultants] = useState(false);
  const callBack = props.callBack;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [countries, setCountries] = useState([]);
  const [courses, setCourses] = useState([]);
  const countryList = [
    "USA",
    "UK",
    "Australia",
    "India",
    "Singapore",
    "Canada",
    "Spain",
    "Germany",
  ];
  const coursesList = ["MBA", "MSBA", "M.Fin", "MiM"];
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const countryFilter = (checkBox, countryName) => {
    let countriesArray = [...countries];
    if (checkBox.checked) {
      if (!countriesArray.includes(countryName)) {
        countriesArray.push(countryName);
        setCountries(countriesArray);
      }
    } else {
      if (countriesArray.includes(countryName)) countriesArray.pop(countryName);
      setCountries(countriesArray);
    }
  };
  const courseFilter = (checkBox, courseName) => {
    let courseArray = [...courses];
    if (checkBox.checked) {
      if (!courseArray.includes(courseName)) {
        courseArray.push(courseName);
      }
    } else {
      if (courseArray.includes(courseName)) {
        courseArray.pop(courseName);
      }
    }
    console.log(courses);
    setCourses(courseArray);
  };
  const {
    data: consultants,
    status,
    refetch: consultantRefetch,
  } = useQuery(["consultants"], async () => {
    let data = await fetch(
      "https://al-connect-production.up.railway.app/consultants/filter",
      {
        method: "POST",
        body: JSON.stringify({
          countries: countries,
          courses: courses,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      },
      {
        onError: (error) => {
          // Call the global error handler with the error object
          console.log("from err", error);
          // errorHandler(error, 'services');
        },
      }
    );
    return await data.json();
  });

  if (status == "loading") {
    return (
      <Box display="flex" mt={10} justifyContent="center" columnGap={10} alignItems="center">
        <Skeleton variant="rectangular" width={300} height={300} />
        <Skeleton variant="rectangular" width={300} height={300} />
        <Skeleton variant="rectangular" width={300} height={300} />
      </Box>
    );
  } else {
    callBack(consultants?.data?.length);
    return (
      <>
        <MKBox component="section" py={2}>
          <Button color="primary" onClick={handleOpen} className="filtersButton">
            Filters
          </Button>
          <Container>
            <Grid container item xs={12} lg={6}>
              <MKTypography variant="h3" mb={6} className={"consultantsHeading"}>
                Expert Guides at Your Service
              </MKTypography>
            </Grid>
            <Grid container spacing={3}>
              {consultants?.data?.map((item, index) => (
                <Grid item xs={12} sm={12} lg={4}>
                  <Link to={"/consultant?id=" + item._id}>
                    <ConsultantCard
                      image={item.imageUrls?.[0]}
                      name={item.name}
                      position={{ color: "info", country: item.country, course: item.degree }}
                      description={item.description}
                      services={item.services}
                      price={item.price}
                      experience={item.experience}
                      placeOfOrigin={item.placeOfOrigin}
                    />
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Container>
        </MKBox>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <MKBox className="filtersBody">
              <Grid container xs={12} lg={12}>
                <Grid item xs={6} lg={6}>
                  <MKTypography variant="h6">Country</MKTypography>
                  <ul style={{ listStyleType: "none" }}>
                    {countryList.map((c) => (
                      <li className="filterOption">
                        <input
                          type="checkbox"
                          onClick={(event) => {
                            countryFilter(event.target, c);
                          }}
                          data-value={c}
                        ></input>
                        <MKTypography variant="button" ml={"8px"}>
                          {c}
                        </MKTypography>
                      </li>
                    ))}
                  </ul>
                </Grid>
                <Grid item xs={6} lg={6} textAlign={"right"}>
                  <MKTypography variant="h6">Degrees</MKTypography>
                  <ul style={{ listStyleType: "none" }}>
                    {coursesList.map((course, index) => (
                      <li key={index} className="filterOption">
                        <MKTypography variant="button" mr={"8px"}>
                          {course}
                        </MKTypography>
                        <input
                          type="checkbox"
                          onClick={(event) => {
                            courseFilter(event.target, course);
                          }}
                        ></input>
                      </li>
                    ))}
                    {/* <li className="filterOption">
                      <MKTypography variant="button" mr={"8px"}>
                        MBA
                      </MKTypography>
                      <input type="checkbox"></input>
                    </li>
                    <li className="filterOption">
                      <MKTypography variant="button" mr={"8px"}>
                        MSBA
                      </MKTypography>
                      <input type="checkbox"></input>
                    </li>
                    <li className="filterOption">
                      <MKTypography variant="button" mr={"8px"}>
                        M.Fin
                      </MKTypography>
                      <input type="checkbox"></input>
                    </li>
                    <li className="filterOption">
                      <MKTypography variant="button" mr={"8px"}>
                        MiM
                      </MKTypography>
                      <input type="checkbox"></input>
                    </li> */}
                  </ul>
                </Grid>
              </Grid>
              <MKBox display="flex" justifyContent="flex-end">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    handleClose();
                    consultantRefetch();
                    setCountries([]);
                    setCourses([]);
                  }}
                >
                  Apply
                </Button>
              </MKBox>
            </MKBox>
          </Box>
        </Modal>
      </>
    );
  }
}

export default Posts;
