import emailjs from "emailjs-com";
export async function sendConfimationEmail({ name, email }) {
  emailjs.init("K8E8Z0gg1HyXvAUTQ");
  const template1 = {
    to_name: name,
    to_email: email,
  };
  const template2 = {
    to_name: name,
    to_email: "info@al-connect.in",
  };
  try {
    await emailjs.send("service_4u2onep", "template_101soon", template1, "K8E8Z0gg1HyXvAUTQ");
    await emailjs.send("service_4u2onep", "template_101soon", template2, "K8E8Z0gg1HyXvAUTQ");
    return true;
  } catch (error) {
    console.error("Failed to send email. Error:", error);
    return false;
  }
}
