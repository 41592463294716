import MKBox from "components/MKBox";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import routes from "routes";
import termsandConditionsImg from "../../../assets/images/2941973-removebg-preview.png";
export const TermsAndConditions = () => {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-react",
          label: "free download",
          color: "default",
        }}
      />
      <MKBox pt={{ lg: "85px", md: "65px", xs: "65px" }}>
        <div className=" p-12">
          <div className=" text-center font-bold text-4xl mb-5">Privacy Policy</div>
          <div className=" flex justify-center">
            <img src={termsandConditionsImg} className=" rounded-xl" height={500} width={500} />
          </div>
          <div className=" mt-3">
            <div className=" font-bold text-xl">Last updated on Jun 25 2024 </div>
            <div className=" mt-2">
              <div className=" mt-2">
                For the purpose of these Terms and Conditions, The term "we", "us", "our" used
                anywhere on this page shall mean AL-CNT SOLUTIONS LLP, whose registered/operational
                office is 1037, Sector 28, Rohini , Sanskriti Apartment North West Delhi DELHI
                110042 . "you", “your”, "user", “visitor” shall mean any natural or legal person who
                is visiting our website and/or agreed to purchase from us.
                <div className=" font-bold text-xl">
                  Your use of the website and/or purchase from us are governed by following Terms
                  and Conditions:
                </div>
                <ul className=" ml-8">
                  <li>
                    The content of the pages of this website is subject to change without notice.
                  </li>
                  <li>
                    Neither we nor any third parties provide any warranty or guarantee as to the
                    accuracy, timeliness, performance, completeness or suitability of the
                    information and materials found or offered on this website for any particular
                    purpose. You acknowledge that such information and materials may contain
                    inaccuracies or errors and we expressly exclude liability for any such
                    inaccuracies or errors to the fullest extent permitted by law.
                  </li>
                  <li>
                    Your use of any information or materials on our website and/or product pages is
                    entirely at your own risk, for which we shall not be liable. It shall be your
                    own responsibility to ensure that any products, services or information
                    available through our website and/or product pages meet your specific
                    requirements.
                  </li>
                  <li>
                    Our website contains material which is owned by or licensed to us. This material
                    includes, but are not limited to, the design, layout, look, appearance and
                    graphics. Reproduction is prohibited other than in accordance with the copyright
                    notice, which forms part of these terms and conditions.
                  </li>
                  <li>
                    All trademarks reproduced in our website which are not the property of, or
                    licensed to, the operator are acknowledged on the website.
                  </li>
                  <li>
                    Unauthorized use of information provided by us shall give rise to a claim for
                    damages and/or be a criminal offense.
                  </li>
                  <li>
                    From time to time our website may also include links to other websites. These
                    links are provided for your convenience to provide further information.
                  </li>
                  <li>
                    You may not create a link to our website from another website or document
                    without AL-CNT SOLUTIONS LLP’s prior written consent.
                  </li>
                  <li>
                    Any dispute arising out of use of our website and/or purchase with us and/or any
                    engagement with us is subject to the laws of India .
                  </li>
                  <li>
                    We, shall be under no liability whatsoever in respect of any loss or damage
                    arising directly or indirectly out of the decline of authorization for any
                    Transaction, on Account of the Cardholder having exceeded the preset limit
                    mutually agreed by us with our acquiring bank from time to time
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </MKBox>
    </>
  );
};
