/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import unis from "assets/images/illustrations/Universities.svg";

// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import educationImg from "assets/images/Education.svg";
function Section1() {
  return (
    <MKBox py={3} mt={0} className="section1">
      <MKTypography variant="h3" textAlign="center">
        Our <span style={{ color: "#FD5523" }}>Mission</span>
      </MKTypography>
      <MKBox display="flex" justifyContent="center">
        <img style={{ width: "100%", maxWidth: "500px" }} src={educationImg}></img>
      </MKBox>
      <MKTypography
        textAlign="center"
        fontSize="16px"
        mt={"18px"}
        style={{ color: "#0C2343", fontFamily: "Noto Sans" }}
        className
      >
        At Al-connect, we believe in empowering students with the tools <br /> and connections they
        need to navigate the complex journey of studying abroad. We've created <br /> a
        community-driven platform where aspiring students can find guidance and mentorship from
        those who've walked the path before them.
      </MKTypography>
      <MKTypography
        textAlign="center"
        fontSize="16px"
        mt={"12px"}
        style={{ color: "#0C2343", fontFamily: "Noto Sans" }}
      >
        Our mission is to make quality mentorship accessible, affordable, and personalized, ensuring
        every student can make informed decisions about their international education journey.
      </MKTypography>
    </MKBox>
  );
}

export default Section1;
