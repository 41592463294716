/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import img2 from "../../../assets/images/fold2.png";

// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import MKTypography from "components/MKTypography";
import applicationAssistanceImg from "../../../assets/svgs/Application assistance.svg";
import sessionWithAlumsImg from "../../../assets/svgs/Session with alums.svg";
import { useRef } from "react";
import { Link } from "react-router-dom";

function Section2() {
  const descRef1 = useRef();
  const descRef2 = useRef();

  const showDescription = (descNo) => {
    if (descNo == 1) {
      descRef1.current.style.bottom = "0";
    } else {
      descRef2.current.style.bottom = "0";
    }
  };
  const hideDescription = (descNo) => {
    if (descNo == 1) {
      descRef1.current.style.bottom = "-100%";
    } else {
      descRef2.current.style.bottom = "-100%";
    }
  };

  return (
    <MKBox component="section" className={"section2"} py={3}>
      <Container>
        <MKTypography className="responsiveHeading">
          <b>We Are Not A Consulting Company</b>
        </MKTypography>
        <MKTypography className="responsiveSubHeading" style={{ color: "#FD5523" }}>
          But a platform that helps students get connected to the mentors of their choice in these
          ways
        </MKTypography>
        <Grid
          container
          item
          xs={12}
          lg={12}
          sx={{ mx: "auto" }}
          display={"flex"}
          justifyContent={"space-evenly"}
          px={{ xs: "16px" }}
        >
          <Grid
            onMouseEnter={() => showDescription(1)}
            onMouseLeave={() => hideDescription(1)}
            item
            xs={12}
            lg={5}
            className="hoverCardContainer"
          >
            <MKBox width="100%">
              <MKBox
                sx={{
                  backgroundImage: "linear-gradient(#f6f6f6, #f6f6f6, white)",
                }}
              >
                <img width={"100%"} style={{ height: "200px" }} src={sessionWithAlumsImg}></img>
              </MKBox>
              <MKBox sx={{ background: "#0C2343" }} p={"12px 24px"}>
                <MKTypography
                  variant="text"
                  textAlign="center"
                  fontSize={{ xs: "16px" }}
                  width="100%"
                  style={{ color: "white" }}
                >
                  Session with alums
                </MKTypography>
              </MKBox>
            </MKBox>
            <Link
              to={"/pages/landing-pages/consultants"}
              ref={descRef1}
              className="section2Desc"
              width="100%"
            >
              <h4>Sessions with alums</h4>
              <ul>
                <li>Mentor of your choice</li>
                <li>60 Mins video Session with the Mentor</li>
                <li> Choose from Profile Building, School selection, or anything you want! </li>
                <li> Pay for a Single Session as you go</li>
                <li> No strings attached!</li>
              </ul>
            </Link>
          </Grid>
          <Grid
            onMouseEnter={() => showDescription(2)}
            onMouseLeave={() => hideDescription(2)}
            item
            xs={12}
            lg={5}
            className="hoverCardContainer"
          >
            <MKBox width="100%">
              <MKBox
                sx={{
                  backgroundImage: "linear-gradient(#f6f6f6, #f6f6f6, white)",
                }}
              >
                <img
                  width={"100%"}
                  style={{ height: "200px" }}
                  src={applicationAssistanceImg}
                ></img>
              </MKBox>
              <MKBox sx={{ background: "#0C2343" }} p={"12px 24px"}>
                <MKTypography
                  variant="text"
                  textAlign="center"
                  fontSize={{ xs: "16px" }}
                  width="100%"
                  style={{ color: "white" }}
                >
                  Full Application Assistance
                </MKTypography>
              </MKBox>
            </MKBox>
            <Link
              to={"/pages/landing-pages/consultants"}
              ref={descRef2}
              className="section2Desc"
              width="100%"
            >
              <h4>Sessions with alums</h4>
              <ul>
                <li>Mentor of your choice</li>
                <li>Profile Building</li>
                <li>College Shortlisting</li>
                <li>SOP/LOR/Resume Guides and Assistance</li>
                <li>Free GMAT/GRE Mock test/prep resources worth 15k</li>
                <li>Interview Prep</li>
                <li>Scholarship assistance All types</li>
                <li>Visa Prep</li>
                <li>Post admit assistance</li>
              </ul>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Section2;
