/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import howItWorksImg from "../../../assets/svgs/How It Works.svg";
import step1 from "../../../assets/svgs/Step One Mobile.svg"
import step2 from "../../../assets/svgs/Step Two Mobile.svg"
import step3 from "../../../assets/svgs/Step Three Mobile.svg"
import step4 from "../../../assets/svgs/Step Four Mobile.svg"
import step5 from "../../../assets/svgs/Step Five Mobile.svg"


// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import MKTypography from "components/MKTypography";

function Section5({ windowWidth }) {
    return (
        <MKBox component="section" py={3} className="Section5">
            <Container>
                <MKTypography className="responsiveHeading">
                    <b>How it works?</b>
                </MKTypography>
                {windowWidth > 768 &&
                    <MKBox mt={'18px'}>
                        <Grid container item xs={12} lg={12} sx={{ mx: "auto", display: 'flex', justifyContent: 'center', gap:'12%' }}>
                            <div style={{ maxWidth: '300px' }}>
                                <MKTypography textAlign="center" fontSize={{ lg: '18px' }} style={{ color: '#FD5523' }}>
                                    <b>Step 2:</b>
                                </MKTypography>
                                <MKTypography textAlign="center" fontSize={{ lg: '16px' }} style={{ color: '#FD5523' }}>
                                    Choose your lighthouse
                                </MKTypography>
                                <MKTypography textAlign="center" fontSize={{ lg: '16px' }} style={{ color: '#85908d', fontFamily:'Noto Sans' }}>
                                    Explore profiles to find your inspiring mentor. Assess their approach and achievements to make an informed choice for your journey.
                                </MKTypography>
                            </div>
                            <div style={{ maxWidth: '300px' }}>
                                <MKTypography textAlign="center" fontSize={{ lg: '18px' }} style={{ color: '#FD5523' }}>
                                    <b>Step 4:</b>
                                </MKTypography>
                                <MKTypography textAlign="center" fontSize={{ lg: '16px' }} style={{ color: '#FD5523' }}>
                                    Smooth Sailing Ahead
                                </MKTypography>
                                <MKTypography textAlign="center" fontSize={{ lg: '16px' }} style={{ color: '#85908d', fontFamily:'Noto Sans' }}>
                                    Our team confirms your session and provides a dedicated support member to address any queries, ensuring a smooth experience.
                                </MKTypography>
                            </div>
                        </Grid>
                        <Grid container item xs={12} lg={12} sx={{ mx: "auto", display: 'flex', justifyContent: 'center' }}>
                            <img width={'97.5%'} src={howItWorksImg}></img>
                        </Grid>
                        <Grid container item xs={12} lg={12} sx={{ mx: "auto", display: 'flex', justifyContent: 'space-between' }}>
                            <MKBox sx={{ maxWidth: {lg:'250px', md:'250px'} }}>
                                <MKTypography textAlign="center" fontSize={{ lg: '18px' }} style={{ color: '#0C2343' }}>
                                    <b>Step 1:</b>
                                </MKTypography>
                                <MKTypography textAlign="center" fontSize={{ lg: '16px' }} style={{ color: '#0C2343' }}>
                                    Browse Your Perfect Compass
                                </MKTypography>
                                <MKTypography textAlign="center" fontSize={{ lg: '16px' }} style={{ color: '#85908d', fontFamily:'Noto Sans' }}>
                                    Quickly filter mentors by university, degree, and expertise to align with your goals and personality.
                                </MKTypography>
                            </MKBox>
                            <MKBox sx={{ maxWidth: {lg:'300px', md:'250px'} }}>
                                <MKTypography textAlign="center" fontSize={{ lg: '18px' }} style={{ color: '#0C2343' }}>
                                    <b>Step 3:</b>
                                </MKTypography>
                                <MKTypography textAlign="center" fontSize={{ lg: '16px' }} style={{ color: '#0C2343' }}>
                                    Chart Your Course
                                </MKTypography>
                                <MKTypography textAlign="center" fontSize={{ lg: '16px' }} style={{ color: '#85908d', fontFamily:'Noto Sans' }}>
                                    Opt for a 45-minute consultation or a full application review. Choose a convenient date and time to begin crafting your study abroad plan.
                                </MKTypography>
                            </MKBox>
                            <MKBox sx={{ maxWidth: {lg:'250px', md:'250px'} }}>
                                <MKTypography textAlign="center" fontSize={{ lg: '18px' }} style={{ color: '#0C2343' }}>
                                    <b>Step 5:</b>
                                </MKTypography>
                                <MKTypography textAlign="center" fontSize={{ lg: '16px' }} style={{ color: '#0C2343' }}>
                                    Bon Voyage!
                                </MKTypography>
                                <MKTypography textAlign="center" fontSize={{ lg: '16px' }} style={{ color: '#85908d', fontFamily:'Noto Sans' }}>
                                    Complete a brief connection form, prepare your questions, and get set for a session filled with insights and strategies for success abroad.
                                </MKTypography>
                            </MKBox>
                        </Grid>
                    </MKBox>}
                {
                    windowWidth < 768 &&
                    <Grid container display={'flex'} justifyContent={'center'} mt={'18px'}>
                        <MKBox>
                            <div style={{ maxWidth: '300px', marginBottom: '18px', display:'flex', justifyContent:'center', flexDirection:'column', alignItems:'center' }}>
                                <img src={step1} width={'95px'}></img>
                                <MKTypography textAlign="center" fontSize={{ xs: '18px' }} style={{ color: '#0C2343' }}>
                                    Step 1:
                                </MKTypography>
                                <MKTypography textAlign="center" fontSize={{ xs: '16px' }} style={{ color: '#0C2343' }}>
                                    Browse Your Perfect Compass
                                </MKTypography>
                                <MKTypography textAlign="center" fontSize={{ xs: '16px' }} style={{ color: '#85908d', fontFamily:'Noto Sans' }}>
                                    Quickly filter mentors by university, degree, and expertise to align with your goals and personality.
                                </MKTypography>
                            </div>
                            <div style={{ maxWidth: '300px', marginBottom: '18px', display:'flex', justifyContent:'center', flexDirection:'column', alignItems:'center'}}>
                                <img src={step2} width={'95px'}></img>
                                <MKTypography textAlign="center" fontSize={{ xs: '18px' }} style={{ color: '#FD5523' }}>
                                    Step 2:
                                </MKTypography>
                                <MKTypography textAlign="center" fontSize={{ xs: '16px' }} style={{ color: '#FD5523' }}>
                                    Choose your lighthouse
                                </MKTypography>
                                <MKTypography textAlign="center" fontSize={{ xs: '16px' }} style={{ color: '#85908d', fontFamily:'Noto Sans' }}>
                                    Explore profiles to find your inspiring mentor. Assess their approach and achievements to make an informed choice for your journey.
                                </MKTypography>
                            </div>
                            <div style={{ maxWidth: '300px', marginBottom: '18px', display:'flex', justifyContent:'center', flexDirection:'column', alignItems:'center' }}>
                                <img src={step3} width={'95px'}></img>
                                <MKTypography textAlign="center" fontSize={{ xs: '18px' }} style={{ color: '#0C2343' }}>
                                    Step 3:
                                </MKTypography>
                                <MKTypography textAlign="center" fontSize={{ xs: '16px' }} style={{ color: '#0C2343' }}>
                                    Chart Your Course
                                </MKTypography>
                                <MKTypography textAlign="center" fontSize={{ xs: '16px' }} style={{ color: '#85908d', fontFamily:'Noto Sans' }}>
                                    Opt for a 45-minute consultation or a full application review. Choose a convenient date and time to begin crafting your study abroad plan.
                                </MKTypography>
                            </div>
                            <div style={{ maxWidth: '300px', marginBottom: '18px', display:'flex', justifyContent:'center', flexDirection:'column', alignItems:'center'}}>
                                <img src={step4} width={'95px'}></img>
                                <MKTypography textAlign="center" fontSize={{ xs: '18px' }} style={{ color: '#FD5523' }}>
                                    Step 4:
                                </MKTypography>
                                <MKTypography textAlign="center" fontSize={{ xs: '16px' }} style={{ color: '#FD5523' }}>
                                    Smooth Sailing Ahead
                                </MKTypography>
                                <MKTypography textAlign="center" fontSize={{ xs: '16px' }} style={{ color: '#85908d', fontFamily:'Noto Sans' }}>
                                    Our team confirms your session and provides a dedicated support member to address any queries, ensuring a smooth experience.
                                </MKTypography>
                            </div>
                            <div style={{ maxWidth: '300px', marginBottom: '18px', display:'flex', justifyContent:'center', flexDirection:'column', alignItems:'center' }}>
                                <img src={step5} width={'95px'}></img>
                                <MKTypography textAlign="center" fontSize={{ xs: '18px' }} style={{ color: '#0C2343' }}>
                                    Step 5:
                                </MKTypography>
                                <MKTypography textAlign="center" fontSize={{ xs: '16px' }} style={{ color: '#0C2343' }}>
                                    Bon Voyage!
                                </MKTypography>
                                <MKTypography textAlign="center" fontSize={{ xs: '16px' }} style={{ color: '#85908d', fontFamily:'Noto Sans' }}>
                                    Complete a brief connection form, prepare your questions, and get set for a session filled with insights and strategies for success abroad.
                                </MKTypography>
                            </div>
                        </MKBox>
                    </Grid>
                }
            </Container >
        </MKBox >
    );
}

export default Section5;