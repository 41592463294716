/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import courseFinder from "../../../assets/images/illustrations/Course Finder.svg";

// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import { useNavigate } from "react-router-dom";
function Section7({ windowWidth }) {
  const navigate = useNavigate();
  return (
    <MKBox component="section" py={3} className="section7">
      <Container>
        <Grid container xs={12} sm={12} md={12} xl={12} lg={12}>
          <Grid container item xs={12} lg={6} justifyContent="left" alignItems="center" mx="auto">
            <MKTypography
              variant="text"
              style={{ color: "#2B3F5B", fontFamily: "Poppins" }}
              px={{ xs: 2, lg: 0 }}
              className="responsiveHeading"
              mt={1}
              textAlign={{ lg: "left", xs: "center" }}
            >
              <b>Overwhelmed By College Research?</b>

              <MKTypography
                className="responsiveSubHeading"
                style={{ color: "#0c2343", fontFamily: "Poppins" }}
                textAlign={{ lg: "left", xs: "center" }}
                mt={1}
              >
                We've got you covered
              </MKTypography>
              <MKTypography
                // className="responsiveSubHeading"
                style={{ color: "grey", fontFamily: "Noto Sans" }}
                textAlign={{ lg: "left", xs: "center" }}
                mt={1}
                fontSize={{ lg: "18px", xs: "16px" }}
              >
                Our AI college Compass will give you the best college matches from all over the
                world as per your career goals and Preferences
              </MKTypography>
              {windowWidth > 768 && (
                <button
                  style={{
                    marginTop: "36px",
                  }}
                  className="primary-btn"
                  onClick={() => {
                    navigate("/start-a-college-research");
                  }}
                >
                  Start A Free College Research
                </button>
              )}
            </MKTypography>
          </Grid>
          <Grid container item xs={12} lg={6} justifyContent="right" mx="auto" mt={{ xs: "20px" }}>
            <img src={courseFinder} width={"100%"}></img>
          </Grid>
          {windowWidth < 768 && (
            <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
              <button
                style={{
                  marginTop: "36px",
                }}
                className="primary-btn-mb"
              >
                Start A Free College Research
              </button>
            </div>
          )}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Section7;
