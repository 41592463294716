/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import step1 from "../../../assets/svgs/Choose you mentor.svg";
import step2 from "../../../assets/svgs/Affordable Transparency.svg";
import step3 from "../../../assets/svgs/No Murky Partnerships Here.svg";
import step4 from "../../../assets/svgs/No strings attached.svg";
import step5 from "../../../assets/svgs/Personalized Guidance.svg";
import step6 from "../../../assets/svgs/Responsive Support.svg";

// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import MKTypography from "components/MKTypography";

function Section4() {
  return (
    <MKBox component="section" py={3} className="section4">
      <Container>
        <MKTypography className="responsiveHeading">
          <b>Why Al-Connect?</b>
        </MKTypography>
        <Grid
          container
          item
          xs={12}
          lg={12}
          sx={{ mx: "auto", display: "flex", justifyContent: "center" }}
        >
          <MKBox
            style={{ backgroundColor: "#FCFCFC" }}
            lg={3}
            className={"stepBox"}
            sx={{
              p: 2,
              borderRadius: "4px",
              filter: "drop-shadow(0px 1px 1px rgba(0,0,0,0.35 ))",
              textAlign: "center",
              m: 1,
            }}
          >
            <MKBox height={"65px"} className=" mb-2">
              <img height={"100%"} width={"65px"} src={step1}></img>
            </MKBox>
            <MKTypography
              variant="p"
              display="block"
              fontSize={"18px"}
              fontWeight={"bold"}
              mt={"8px"}
              style={{ color: "#FD5523" }}
            >
              Choose your own mentor
            </MKTypography>
            <MKTypography
              variant="text"
              fontSize="14px"
              style={{ color: "#0C2343", maxWidth: "300px", fontFamily: "Noto Sans" }}
            >
              Ditch the Random "Counsellors". Our platform connects you to verified mentors who've
              actually walked the study abroad path in your chosen field and college.
            </MKTypography>
          </MKBox>
          <MKBox
            style={{ backgroundColor: "#FCFCFC" }}
            lg={3}
            className={"stepBox"}
            sx={{
              p: 2,
              borderRadius: "4px",
              filter: "drop-shadow(0px 1px 1px rgba(0,0,0,0.35 ))",
              textAlign: "center",
              m: 1,
            }}
          >
            <MKBox height={"65px"} className=" mb-2">
              <img height={"100%"} width={"55px"} src={step2}></img>
            </MKBox>
            <MKTypography
              variant="p"
              display="block"
              fontSize={"18px"}
              fontWeight={"bold"}
              mt={"8px"}
              style={{ color: "#FD5523" }}
            >
              Affordable Transparency
            </MKTypography>
            <MKTypography
              variant="text"
              fontSize="14px"
              style={{ color: "#0C2343", maxWidth: "300px", fontFamily: "Noto Sans" }}
            >
              Ditch the hefty packages. Choose your mentor, book individual sessions, or opt for
              tailored guidance packages - all at a fraction of the consulting firm's.
            </MKTypography>
          </MKBox>
          <MKBox
            style={{ backgroundColor: "#FCFCFC" }}
            lg={3}
            className={"stepBox"}
            sx={{
              p: 2,
              borderRadius: "4px",
              filter: "drop-shadow(0px 1px 1px rgba(0,0,0,0.35 ))",
              textAlign: "center",
              m: 1,
            }}
          >
            <MKBox height={"65px"} className=" mb-1">
              <img height={"100%"} width={"65px"} src={step3}></img>
            </MKBox>
            <MKTypography
              variant="p"
              display="block"
              fontSize={"18px"}
              fontWeight={"bold"}
              mt={"8px"}
              style={{ color: "#FD5523" }}
            >
              No Murky Partnerships Here
            </MKTypography>
            <MKTypography
              variant="text"
              fontSize="14px"
              style={{ color: "#0C2343", maxWidth: "300px", fontFamily: "Noto Sans" }}
            >
              Our mentors offer honest guidance, free from college shackles. Trust their insights,
              crack your dream admission.
            </MKTypography>
          </MKBox>
        </Grid>
        <Grid
          container
          item
          xs={12}
          lg={12}
          sx={{ mx: "auto", display: "flex", justifyContent: "center" }}
        >
          <MKBox
            style={{ backgroundColor: "#FCFCFC" }}
            lg={3}
            className={"stepBox"}
            sx={{
              p: 2,
              borderRadius: "4px",
              filter: "drop-shadow(0px 1px 1px rgba(0,0,0,0.35 ))",
              textAlign: "center",
              m: 1,
            }}
          >
            <MKBox height={"65px"} className=" mb-2">
              <img height={"100%"} width={"65px"} src={step4}></img>
            </MKBox>
            <MKTypography
              variant="p"
              display="block"
              fontSize={"18px"}
              fontWeight={"bold"}
              mt={"8px"}
              style={{ color: "#FD5523" }}
            >
              No strings attached
            </MKTypography>
            <MKTypography
              variant="text"
              fontSize="14px"
              style={{ color: "#0C2343", maxWidth: "300px", fontFamily: "Noto Sans" }}
            >
              Pay per session, ditch long-term commitments. Learn from masters, refine your plans,
              and conquer deadlines - all on your terms.
            </MKTypography>
          </MKBox>
          <MKBox
            style={{ backgroundColor: "#FCFCFC" }}
            lg={3}
            className={"stepBox"}
            sx={{
              p: 2,
              borderRadius: "4px",
              filter: "drop-shadow(0px 1px 1px rgba(0,0,0,0.35 ))",
              textAlign: "center",
              m: 1,
            }}
          >
            <MKBox height={"65px"} className=" mb-2">
              <img height={"100%"} width={"65px"} src={step5}></img>
            </MKBox>
            <MKTypography
              variant="p"
              display="block"
              fontSize={"18px"}
              fontWeight={"bold"}
              mt={"8px"}
              style={{ color: "#FD5523" }}
            >
              Personalized Guidance
            </MKTypography>
            <MKTypography
              variant="text"
              fontSize="14px"
              style={{ color: "#0C2343", maxWidth: "300px", fontFamily: "Noto Sans" }}
            >
              Our platform guarantees personalized support from your mentor. Get your questions
              answered, doubts cleared, and anxieties addressed - all with dedicated attention.
            </MKTypography>
          </MKBox>
          <MKBox
            style={{ backgroundColor: "#FCFCFC" }}
            lg={3}
            className={"stepBox"}
            sx={{
              p: 2,
              borderRadius: "4px",
              filter: "drop-shadow(0px 1px 1px rgba(0,0,0,0.35 ))",
              textAlign: "center",
              m: 1,
            }}
          >
            <MKBox height={"65px"} className=" mb-2">
              <img height={"100%"} width={"55px"} src={step6}></img>
            </MKBox>
            <MKTypography
              variant="p"
              display="block"
              fontSize={"18px"}
              fontWeight={"bold"}
              mt={"8px"}
              style={{ color: "#FD5523" }}
            >
              Responsive Support
            </MKTypography>
            <MKTypography
              variant="text"
              fontSize="14px"
              style={{ color: "#0C2343", maxWidth: "300px", fontFamily: "Noto Sans" }}
            >
              Our dedicated support team ensures your mentorship journey stays on track. They
              proactively monitor session schedules, review application progress, and offer guidance
              whenever needed.
            </MKTypography>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Section4;
