// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logos/Al-Connect horizontal white Logo.svg";

const date = new Date().getFullYear();
export default {
  brand: {
    name: "Material Kit 2",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/CreativeTim/",
    },
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com/creativetim",
    },
    {
      icon: <GitHubIcon />,
      link: "https://github.com/creativetimofficial",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/channel/UCVyTG4sCw-rOvB9oHkzZD1w",
    },
  ],
  menus: [
    {
      name: "our services",
      items: [
        { name: "Alumni Services", href: "/pages/landing-pages/consultants" },
        { name: "Application Assistance", href: "/pages/landing-pages/consultants" },
      ],
    },
    {
      name: "resources",
      items: [{ name: "College Compass", href: "/consultants" }],
    },
    {
      name: "company",
      items: [
        { name: "about us", href: "/pages/landing-pages/about-us" },
        { name: "contact us", href: "/pages/landing-pages/contact-us" },
        { name: "How are we different", href: "/" },
        { name: "Privacy Policy", href: "/privacy-policy" },
        { name: "Refund Policy", href: "/refund-policy" },
        { name: "Terms and Conditions", href: "/terms-conditions" },
      ],
    },
    {
      name: "free guidance session",
      items: [
        {
          name: (
            <button
              style={{
                marginTop: "6px",
                padding: "12px",
              }}
              className="primary-btn-mb"
            >
              Book a Free Session
            </button>
          ),
          href: "/speak-with-experts",
        },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date}{" "}
      <MKTypography
        component="a"
        href="/"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Al-connect
      </MKTypography>
      .
    </MKTypography>
  ),
};
