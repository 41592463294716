import MKBox from "components/MKBox";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import routes from "routes";
export const StartACollegeResearch = () => {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-react",
          label: "free download",
          color: "default",
        }}
      />
      <MKBox pt={{ lg: "85px", md: "65px", xs: "65px" }}>
        <div className=" mt-10 text-center font-bold text-2xl">Coming Soon...</div>
      </MKBox>
    </>
  );
};
