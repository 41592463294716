import React from "react";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import routes from "routes";
import deakin from "../../../src/assets/uni-logos/deakin.png";
import eramusmundas from "../../../src/assets/uni-logos/eramusmundas.webp";
import escade from "../../../src/assets/uni-logos/escade.webp";
import esmt from "../../../src/assets/uni-logos/esmt.png";
import imperial from "../../../src/assets/uni-logos/imperial.png";
import lbs from "../../../src/assets/uni-logos/lbs.jpg";
import mit from "../../../src/assets/uni-logos/mit.jpg";
import nus from "../../../src/assets/uni-logos/nus.jpg";
import nyu from "../../../src/assets/uni-logos/nyu.png";
import ucd from "../../../src/assets/uni-logos/ucd.png";
import utidalla from "../../../src/assets/uni-logos/utidalla.png";
import uniOfsydney from "../../../src/assets/uni-logos/uni-of-sydney.png";
import utiaustin from "../../../src/assets/uni-logos/utiaustin.png";

import lse from "../../../src/assets/uni-logos/lse.jpg";
import duke from "../../../src/assets/uni-logos/duke.png";
import cornell from "../../../src/assets/uni-logos/cornell.png";
import isb from "../../../src/assets/uni-logos/isb.jpeg";
import esade from "../../../src/assets/uni-logos/escade.png";
import sse from "../../../src/assets/uni-logos/sse.jpeg";
import bocconi from "../../../src/assets/uni-logos/boconni.png";
import escp from "../../../src/assets/uni-logos/escp.png";
import edhec from "../../../src/assets/uni-logos/edhec.png";
import schulich from "../../../src/assets/uni-logos/schu.webp";
import ubc from "../../../src/assets/uni-logos/ubc.webp";

export const MoreUniversities = () => {
  const universities = [
    { src: deakin, alt: "Deakin University" },
    { src: eramusmundas, alt: "Eramus Mundas" },
    { src: escade, alt: "Escade" },
    { src: esmt, alt: "ESMT" },
    { src: imperial, alt: "Imperial College" },
    { src: lbs, alt: "London Business School" },
    { src: mit, alt: "MIT" },
    { src: nus, alt: "National University of Singapore" },
    { src: nyu, alt: "New York University" },
    { src: ucd, alt: "University College Dublin" },
    { src: uniOfsydney, alt: "University of Sydney" },
    { src: utiaustin, alt: "University of Texas at Austin" },
    { src: utidalla, alt: "University of Texas at Dallas" },
    { src: lse, alt: "London School of Economics" },
    { src: duke, alt: "Duke University" },
    { src: cornell, alt: "Cornell University" },
    { src: isb, alt: "Indian School of Business" },
    { src: esade, alt: "ESADE" },
    { src: sse, alt: "Stockholm School of Economics" },
    { src: bocconi, alt: "Bocconi University" },
    { src: escp, alt: "ESCP Business School" },
    { src: edhec, alt: "EDHEC Business School" },
    { src: schulich, alt: "Schulich School of Business" },
    { src: ubc, alt: "University of British Columbia" },
  ];

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-react",
          label: "free download",
          color: "default",
        }}
      />
      <MKBox pt={{ lg: "85px", md: "65px", xs: "65px" }}>
        <div className="p-12">
          <MKTypography className="responsiveHeading">
            <b>We Have Consultants From These Colleges</b>
          </MKTypography>
          <div className="grid grid-cols-4 gap-8 justify-center p-10">
            {universities.map((university, index) => (
              <div key={index} className=" shadow-md rounded-sm flex justify-center items-center">
                {" "}
                <img
                  src={university.src}
                  alt={university.alt}
                  width={200}
                  height={200}
                  className=" min-w-[70%] rounded-md max-h-52"
                />
              </div>
            ))}
          </div>
        </div>
      </MKBox>
    </>
  );
};
