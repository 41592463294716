/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import unis from "assets/images/illustrations/Universities.svg";

// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import educationImg from "assets/images/Education.svg";
function Featuring() {
  return (
    <MKBox py={3} mt={0} className="featuring">
      <MKTypography variant="h3" textAlign="center">
        Our <span style={{ color: "#FD5523" }}>Story</span>
      </MKTypography>
      <MKTypography
        textAlign="center"
        fontSize="16px"
        mt={"18px"}
        style={{ color: "#0C2343", fontFamily: "Noto Sans" }}
      >
        Al-connect was born out of a simple observation: <br />
        the journey to studying abroad is often cluttered with misinformation ,<br /> high costs,
        and impersonal guidance. We envisioned a platform where knowledge <br />
        and experiences are shared freely, connections are made meaningfully, and every student can
        find a mentor
        <br /> who not only guides but truly understands their journey. Our community of mentors
        from diverse colleges and fields <br />
        is the heart of our platform , each bringing unique perspectives and invaluable insights to
        help students achieve their dreams.
      </MKTypography>
    </MKBox>
  );
}

export default Featuring;
