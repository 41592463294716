/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import unis from "../../../assets/images/illustrations/Universities.svg";

// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import { useNavigate } from "react-router-dom";

function Section6() {
  const navigate = useNavigate();
  return (
    <MKBox component="section" py={3} className="section6">
      <MKTypography className="responsiveHeading">
        <b>We Have Consultants From These Colleges</b>
      </MKTypography>
      <MKBox px={{ xs: "16px", lg: "48px" }} mt={"18px"}>
        <img src={unis} width={"100%"}></img>
      </MKBox>
      <MKBox display={"flex"} justifyContent={"center"} width={"100%"}>
        <button
          onClick={() => {
            navigate("/more-universities");
          }}
          style={{
            marginTop: "36px",
          }}
          className="primary-btn-mb"
        >
          View More Universities
        </button>
      </MKBox>
    </MKBox>
  );
}

export default Section6;
