/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useMediaQuery } from "@mui/material";
//slick carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./BookingPreference.css";
import "../../../../styles/commonStyles.css";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import moment from "moment";
import $ from "jquery";

//tabs
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

// Images
import bgImage from "assets/images/examples/blog2.jpg";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MKBadge from "components/MKBadge";
import { ApplicationAssistanceCard } from "./applicationAssistanceCard";

function BookingForm(props) {
  const today = new Date();
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const [isLoaded, setIsLoaded] = useState(false);
  const [dates, setDates] = useState([]);
  const [slots, setSlots] = useState([]);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [date, setDate] = useState(today.getDate());
  const [month, setMonth] = useState(today.getMonth() + 1);
  const [day, setDay] = useState(today.getDay() + 1);
  const [count, setCount] = useState(0);
  const [timeSlot, setTimeSlot] = useState([]);
  const [price, setPrice] = useState(0);
  const year = today.getFullYear();
  const momentToday = moment(today);
  const [buttonDisplay, setButtonDisplay] = useState("none");
  let nowHour = today.getHours();
  const mlist = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dayList = {
    1: "Sun",
    2: "Mon",
    3: "Tue",
    4: "Wed",
    5: "Thurs",
    6: "Fri",
    7: "Sat",
  };

  //slider settings
  var settings1 = {
    accessibility: true,
    arrows: true,
    autoplay: false,
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 3,
  };
  var settings2 = {
    accessibility: true,
    arrows: true,
    autoplay: false,
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
  };

  const dateHandler = function (e) {
    $(".dates").removeClass("active");
    $(e.target.closest(".dates")).addClass("active");
    setDate(e.target.closest(".dates").dataset.date);
    setMonth(e.target.closest(".dates").dataset.month);
    setDay(e.target.closest(".dates").dataset.day);
    setCount(e.target.closest(".dates").dataset.count);
  };
  let compareSlot = (a, b) => {
    return parseInt(a.startTime) - parseInt(b.startTime);
  };
  let handleTimeslot = (e) => {
    if (document.getElementsByClassName("picked").length > 0) {
      var pickedLen = document.getElementsByClassName("picked").length - 1;
      var lastIndex = parseInt(document.getElementsByClassName("picked")[pickedLen].dataset.index);
      var firsIndex = parseInt(document.getElementsByClassName("picked")[0].dataset.index);
      var currentIndex = parseInt(e.target.closest(".slotBtn").dataset.index);
    } else {
      var lastIndex = 0;
      var firstIndex = 0;
      var currentIndex = 0;
    }
    if (!$(e.target.closest(".slotBtn")).hasClass("picked")) {
      if (
        currentIndex == lastIndex + 1 ||
        currentIndex == firsIndex - 1 ||
        document.getElementsByClassName("picked").length == 0
      ) {
        let tempSlot = timeSlot;
        $(e.target.closest(".slotBtn")).addClass("picked");
        setPrice(price + parseInt(e.target.closest(".slotBtn").dataset.price));
        tempSlot.push({
          startTime: e.target.closest(".slotBtn").dataset.value,
          weekDayCode: day,
          duration: 60,
        });
        tempSlot.sort(compareSlot);
        setTimeSlot(tempSlot);
      }
    } else {
      if (
        currentIndex == lastIndex ||
        currentIndex == firsIndex ||
        document.getElementsByClassName("picked").length == 1
      ) {
        $(e.target).closest(".slotBtn").removeClass("picked");
        setPrice(price - parseInt(e.target.closest(".slotBtn").dataset.price));
        let temp = timeSlot;
        temp.forEach((t, i) => {
          if (t.startTime == e.target.closest(".slotBtn").dataset.value) {
            temp.splice(i, 1);
          }
        });
        setTimeSlot(temp);
      }
    }
  };
  const consultantId = props.consultantId;
  const pricePerSession = props.pricePerSession;
  const features = props.features;
  useEffect(async () => {
    fetch("https://al-connect-production.up.railway.app/slots?consultantId=" + consultantId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setTimeout(() => {
            $(".dates:first-child").addClass("active");
          }, 100);
          setIsLoaded(true);
          setSlots(result.data);
        },
        //Note: it's important to handle errors here
        // instead of a catch() block so thatwe don't wallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log(error);
          setIsLoaded(true);
          setError(error);
        }
      );
    let i = 7;
    let d = 0;
    let j = 1;
    let nowDate = today.getDate();
    let nowMonth = today.getMonth() + 1;
    let lastDay;
    let datee = [];
    let temp;
    setDates([]);
    if ((nowMonth <= 7 && nowMonth % 2 != 0) || (nowMonth >= 8 && nowMonth % 2 == 0)) lastDay = 31;
    else lastDay = 30;
    if (nowMonth == 2) {
      lastDay = 28;
    }
    let flag = 0;
    while (i > 0) {
      if (nowDate + d > lastDay && flag == 0) {
        d = 0;
        nowDate = 1;
        nowMonth++;
        flag = 1;
      }
      temp = momentToday.add(1, "day").day();
      temp = temp ? temp : 7;
      datee.push({ date: nowDate + d, month: nowMonth, day: temp });

      d++;
      i--;
      j++;
    }
    setDates(datee);
  }, []);

  const tConvert = (time) => {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  };
  if (0) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return (
      <lottie-player
        src="images/Loader.json"
        background="transparent"
        speed="1"
        style={{ width: "300px", height: "100%" }}
        loop
        autoplay
      ></lottie-player>
    );
  } else {
    return (
      <MKBox component="section" xs={12} sm={12}>
        <Container xs={12} sm={12}>
          <Grid container item xs={12} sm={12}>
            <MKBox
              width="100%"
              bgColor=""
              borderRadius="xl"
              shadow="xl"
              mb={6}
              sx={{ overflow: "hidden" }}
              xs={12}
              sm={12}
              className=" bg-[#f2f2f2]"
            >
              <Grid container px={1} spacing={2} xs={12} sm={12}>
                <Grid item xs={12} lg={12}>
                  <MKBox component="form" py={1} method="post">
                    <MKBox>
                      <MKTypography variant={isSmallScreen ? "h4" : "h1"} mb={1}>
                        Ready to Get into the <span className=" text-[#FD5523]">Best College?</span>
                      </MKTypography>
                      <div className=" font-extrabold mb-2  text-lg text-black">
                        We know the Pathway, lets walk together
                      </div>
                    </MKBox>
                    {/* Tabs */}
                    <Tabs defaultIndex={1}>
                      <TabList className={" border-none my-8"}>
                        <Tab>
                          <MKTypography variant="body1" color="body1">
                            Video Session
                          </MKTypography>
                        </Tab>
                        <Tab>
                          <MKTypography variant="body1" color="body1">
                            Application Assistance
                          </MKTypography>
                        </Tab>
                      </TabList>
                      <TabPanel>
                        <MKBox>
                          <MKBox px={3} pt={0}>
                            <div className=" text-2xl font-bold">
                              Cost/Session -
                              <span className=" align-top  text-xs rupee">&#8377;</span> 6500/hr
                            </div>
                            <div className="text-md">
                              To have a one on one Session with the Mentor, just book a call with us
                              below or mail us at info@al-connect.in{" "}
                            </div>
                          </MKBox>

                          {/* <MKBox pt={0.5} pb={3} px={3}>
                            <Slider {...settings1}>
                              {dates.map((d, i) => (
                                <Grid item xs={"auto"} pr={1} mb={1}>
                                  <MKBox
                                    onClick={(event) => {
                                      dateHandler(event);
                                    }}
                                    id={d.day}
                                    data-date={d.date}
                                    data-day={d.day}
                                    data-month={d.month}
                                    data-count={i}
                                    className="dates"
                                    color="white"
                                    bgColor="info"
                                    variant="gradient"
                                    borderRadius="lg"
                                    shadow="none"
                                    opacity={1}
                                    p={2}
                                  >
                                    <p>{dayList[d.day]}</p>
                                    {d.date} {mlist[d.month - 1]}
                                  </MKBox>
                                </Grid>
                              ))}
                            </Slider>
                            <Slider {...settings2}>
                              {slots[count].slots.map((s) =>
                                day == today.getDay() + 1 ? (
                                  parseInt(s.id) > nowHour ? (
                                    <Grid item xs={"auto"} mb={1} textAlign={"center"}>
                                      <MKBadge
                                        color="dark"
                                        size="sm"
                                        indicator
                                        border
                                        // bgColor="dark"
                                        variant="gradient"
                                        borderRadius="24px"
                                        shadow="none"
                                        opacity={1}
                                        p={2}
                                        className="slotBtn"
                                        onClick={(event) => handleTimeslot(event)}
                                        data-index={s.id}
                                        data-value={s.time}
                                        data-price={s.cost}
                                      >
                                        {tConvert(s.time)}
                                      </MKBadge>
                                    </Grid>
                                  ) : null
                                ) : (
                                  <Grid item xs={"auto"} mb={1} textAlign={"center"}>
                                    <MKBadge
                                      color="dark"
                                      size="sm"
                                      indicator
                                      border
                                      // bgColor="dark"
                                      variant="gradient"
                                      borderRadius="lg"
                                      shadow="none"
                                      opacity={1}
                                      p={2}
                                      className="slotBtn"
                                      onClick={(event) => handleTimeslot(event)}
                                      data-index={s.id}
                                      data-value={s.time}
                                      data-price={s.cost}
                                    >
                                      {tConvert(s.time)}
                                    </MKBadge>
                                  </Grid>
                                )
                              )}
                            </Slider>
                            <Grid container>
                              <Grid item xs={12} p={"12px"} mb={6}>
                                <MKInput
                                  variant="standard"
                                  label="Anything particular you would like to discuss?"
                                  placeholder=""
                                  InputLabelProps={{ shrink: true }}
                                  fullWidth
                                  multiline
                                  rows={6}
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              item
                              xs={12}
                              md={6}
                              justifyContent="flex-end"
                              textAlign="right"
                              ml="auto"
                            >
                              <Link to="/invoice">
                                <MKButton variant="gradient" color="info">
                                  Book an Appointment
                                </MKButton>
                              </Link>
                            </Grid>
                          </MKBox> */}
                        </MKBox>
                      </TabPanel>
                      <TabPanel>
                        <div className=" mb-8 text-center text-2xl font-extrabold">
                          Select Your Plan Now
                        </div>
                        <MKBox
                          className={"cardsContainer"}
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <MKBox className={"cardsBox"} mr={2}>
                            <div>
                              <ApplicationAssistanceCard
                                plan={"AL-STARTER"}
                                noOfColleges={4}
                                price={props.degree == "MBA" ? 67999 : 45999}
                                beforePrice={100000}
                                features={features}
                                index={0}
                              />
                            </div>
                          </MKBox>
                          <MKBox className={"cardsBox"} mr={2}>
                            <ApplicationAssistanceCard
                              recommended={true}
                              plan={"AL-PRO"}
                              noOfColleges={6}
                              price={props.degree == "MBA" ? 97999 : 69999}
                              beforePrice={125000}
                              index={1}
                            />
                          </MKBox>
                          <MKBox className={"cardsBox"} mr={2}>
                            <ApplicationAssistanceCard
                              plan={"AL-ADVANCED"}
                              noOfColleges={8}
                              price={props.degree == "MBA" ? 115000 : 87999}
                              beforePrice={150000}
                              index={2}
                            />
                          </MKBox>
                        </MKBox>
                      </TabPanel>
                    </Tabs>
                  </MKBox>
                </Grid>
              </Grid>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
    );
  }
}

export default BookingForm;
