/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import img2 from "../../../assets/images/fold2.png";

// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import MKTypography from "components/MKTypography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPiggyBank, faChalkboardTeacher, faDirections, faCheck } from '@fortawesome/free-solid-svg-icons'
import scammedImg from '../../../assets/svgs/Affraid of getting.svg'
function Section3() {
    return (
        <MKBox component="section" className={"section3"} py={'32px'}>
            <Container>
                <MKTypography className="responsiveHeading">
                    <b>Afraid Of Getting Scammed By Study Abroad Consultants?</b>
                </MKTypography>
                <MKTypography className="responsiveSubHeading">
                    Do you know <span style={{ color: '#FD5523' }}>80% of the consulting students</span> are feel unheard and undeserved
                </MKTypography>
                <Grid container item xs={12} lg={12} sx={{ mx: "auto" }} mt={'18px'} >
                    <Grid item xs={12} lg={6} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <img src={scammedImg} style={{width:'90%'}}></img>
                    </Grid>
                    <Grid item xs={12} lg={5.8} ml={'10px'}>
                        <MKTypography className="responsiveSubHeading" fontFamily='Noto Sans' fontSize={{lg:'18px!important'}} textAlign={{lg:'left!important', xs:'center'}}>
                            <b>Problems Study abroad aspirants face:</b>
                        </MKTypography>
                        <ul className="styledList">
                            <li style={{marginTop:'12px'}}>
                                <MKTypography variant='listItem' fontSize={{xs:'14px', lg:'16px'}}>
                                    <b>Lack of transparency:</b> Students cannot choose their own mentor and are often stuck with them for the whole journey. Isn't that scary?
                                </MKTypography>
                            </li>
                            <li style={{marginTop:'12px'}}>
                                <MKTypography variant='listItem' fontSize={{xs:'14px', lg:'16px'}}>
                                    <b>Hefty Packages:</b> Students spend lakhs thinking this will ensure guaranteed admission and Expert guidance and are still left feeling empty-handed.
                                </MKTypography>
                            </li>
                            <li style={{marginTop:'12px'}}>
                                <MKTypography variant='listItem' fontSize={{xs:'14px', lg:'16px'}}>
                                    <b>Blind Guidance:</b> In most cases, Students work with people called "Counsellors", who have never been abroad. Can you trust their advice?
                                </MKTypography>
                            </li>
                            <li style={{marginTop:'12px'}}>
                                <MKTypography variant='listItem' fontSize={{xs:'14px', lg:'16px'}}>
                                    <b>Honesty on hold:</b> They pay up and then get steered towards partnered colleges. Their dreams are compromised for Profit margins.
                                </MKTypography>
                            </li>
                            <li style={{marginTop:'12px'}}>
                                <MKTypography variant='listItem' fontSize={{xs:'14px', lg:'16px'}}>
                                    <b>Ghosted Guidance:</b> Messages are often left on read, calls unanswered just because you paid everything upfront.
                                </MKTypography>
                            </li>
                        </ul>
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    );
}

export default Section3;