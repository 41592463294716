import confirm from "../../../assets/bookingpage/confirm 1.png";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MKBox from "components/MKBox";
import React, { useEffect } from "react";
import routes from "routes";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
export const Confirmation = () => {
  // const style = {
  //   position: "absolute",
  //   top: "50%",
  //   left: "50%",
  //   transform: "translate(-50%, -50%)",
  //   width: 600,
  //   bgcolor: "background.paper",
  //   border: "2px solid #000",
  //   boxShadow: 24,
  //   p: 4,
  // };
  useEffect(() => {
    if (window.fbq) {
      window.fbq("track", "Lead");
    }
    if (typeof gtag === "function") {
      gtag("event", "conversion", {
        send_to: "AW-16646579165/W0gDCPG22cMZEN3H2oE-",
      });
    }
  }, []);
  return (
    <div className=" bg-white min-w-full">
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-react",
          label: "free download",
          color: "default",
        }}
      />
      <MKBox pt={{ lg: "85px", md: "65px", xs: "65px" }} className=" w-full">
        <div className=" flex justify-center items-center mt-2 p-3 md:p-0 h-screen">
          <Box className=" border-2 shadow-lg p-5">
            <Typography id="modal-modal-title" variant="h5" component="h2" className=" text-2xl">
              Your Appointment has been booked.
              <div className=" w-full flex justify-center p-3">
                <img src={confirm} height={70} width={70} />
              </div>
            </Typography>
            <Typography id="modal-modal-description" className="" sx={{ mt: 2 }}>
              Kindly Check your email for the confirmation
              <div className=" w-full h-[10px] bg-orange-500"></div>
            </Typography>
          </Box>
        </div>
      </MKBox>
    </div>
  );
};
