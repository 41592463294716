import MKBox from "components/MKBox";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import routes from "routes";
import refundPolicyImg from "assets/images/refund-policy-img.png";
export const RefundPolicy = () => {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-react",
          label: "free download",
          color: "default",
        }}
      />
      <MKBox pt={{ lg: "85px", md: "65px", xs: "65px" }}>
        <div className=" p-12">
          <div className=" text-center font-bold text-4xl">Refund Policy</div>
          <div className=" flex justify-center">
            <img src={refundPolicyImg} height={500} width={500} />
          </div>
          <div className="mt-3">
            <div className=" font-bold text-2xl">Refund Policy</div>
            <div>
              At al-connect, operated by AL-CNt SOLUTIONS LLP, we are committed to ensuring that our
              customers are completely satisfied with our services. We understand the importance of
              meeting your expectations and strive to provide exceptional service at every step of
              your study abroad journey. We genuinely value your trust in us and will make every
              effort to resolve any issues you may encounter.
            </div>
          </div>
          <div className="mt-3">
            <div className=" font-bold text-2xl">Satisfaction Guarantee</div>
            <div>
              All our services and products come with a 14-day satisfaction guarantee. If, for any
              concrete reason, you are not 100% satisfied with the services provided within the
              first 14 days, you are eligible for a refund. To request a refund under this
              guarantee, please contact us within 14 days of your purchase, explaining the reasons
              for your dissatisfaction.
            </div>
          </div>
          <div className="mt-3">
            <div className=" font-bold text-2xl">Non-Refundable Services</div>
            <div>
              Please note that any services or products purchased beyond the 14-day satisfaction
              guarantee period are non-refundable. We encourage you to reach out to us within the
              guarantee period if you have any concerns so that we can address them promptly.
            </div>
          </div>
          <div className="mt-3">
            <div className=" font-bold text-2xl">Special Conditions</div>
            <div>
              In certain exceptional cases, we may review refund requests that fall outside of the
              14-day satisfaction guarantee. These cases will be reviewed at the discretion of the
              founders and our team. There is no obligation for us to offer a refund, but based on
              the review, we may decide to grant a partial refund. Customers will be notified of the
              decision following the review.
            </div>
          </div>
          <div className="mt-3">
            <div className=" font-bold text-2xl">Partial Payments</div>
            <div>
              If you opt for a partial payment plan, please be aware that you must complete the
              payment as agreed. Failure to complete the payment may result in the termination of
              services. We strongly encourage you to adhere to the payment schedule to avoid any
              interruptions in your service.
            </div>
          </div>
          <div className=" mt-3">
            <div className=" font-bold text-2xl"> Refund Process</div>
            <div className=" mt-2">
              To request a refund, please follow these steps:
              <ol className=" ml-8">
                <li>Contact our customer support team at info@al-connect.in</li>
                <li>
                  Provide proof of purchase and a detailed explanation of the reasons for your
                  dissatisfaction.
                </li>
                <li>
                  Our team will review your request and respond within a reasonable timeframe.
                </li>
                <li>
                  {" "}
                  If Eligible, the refund would be processed in 7 Business and credited in 10-12
                  days
                </li>
              </ol>
            </div>
          </div>
          <div className="mt-3">
            <div className=" font-bold text-2xl">Refund Method</div>
            <div>
              Approved refunds will be issued using the original payment method. Please note that
              processing times may vary depending on your payment provider.
            </div>
            <div>
              We appreciate your understanding and cooperation. Our goal is to ensure your
              satisfaction and support your academic journey to the best of our abilities.
            </div>
          </div>
        </div>
      </MKBox>
    </>
  );
};
