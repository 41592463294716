/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Author page sections
import Profile from "pages/LandingPages/SingleConsultant/sections/Profile";
// import Posts from "pages/LandingPages/SingleConsultant/sections/Posts";
import BookingForm from "pages/LandingPages/SingleConsultant/sections/BookingForm";
import Footer from "pages/LandingPages/SingleConsultant/sections/Footer";

// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/city-profile.jpg";
import newBgImage from "assets/images/consultantsDesktopBG.png";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";
import { BGImages } from "./sections/BgImageConfig";
function SingleConsultant() {
  const [queryParameters] = useSearchParams();
  // const [consultantData, setConsultantData] = useState({})
  const [isLoaded, setIsLoaded] = useState(false);
  let consultantId = queryParameters.get("id");
  // const {
  //   data: consultantData,
  //   status,
  //   refetch: consultantDataRefetch
  // } = useQuery('consultantData', () =>
  //   fetch('https://al-connect-production.up.railway.app/consultants/' + consultantId, {
  //     method: 'GET',
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   }).then(res => { return res.json })
  // .then(res => {return res.data})

  // data = await data.json();
  // return data.data
  // )

  const {
    data: consultantData,
    status,
    refetch: consultantDataRefetch,
  } = useQuery("consultantData", () =>
    fetch(
      "https://al-connect-production.up.railway.app/consultants/" + consultantId,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
        },
      },
      {
        onError: (error) => {
          // Call the global error handler with the error object
          console.log("from err", error);
          // errorHandler(error, 'services');
        },
      }
    )
      .then((res) => {
        if (res.status == 403) {
          router.push("/");
        }
        console.log(res, res.status);
        return res.json();
      })
      .then((res) => {
        return res.data;
      })
  );

  if (consultantData) {
    return (
      <>
        <DefaultNavbar
          routes={routes}
          action={{
            type: "external",
            route: "https://www.creative-tim.com/product/material-kit-react",
            label: "free download",
            color: "default",
          }}
        />
        <MKBox bgColor="white">
          <MKBox
            minHeight="25rem"
            width="100%"
            sx={{
              backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                `${linearGradient(
                  rgba(gradients.dark.main, 0.1),
                  rgba(gradients.dark.state, 0.1)
                )}, url(${BGImages[consultantData.name] ?? newBgImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: "grid",
              placeItems: "center",
            }}
          />
          <Card
            sx={{
              p: 2,
              mt: -10,
              mb: 4,
              boxShadow: ({ boxShadows: { xxl } }) => xxl,
            }}
          >
            <Profile consultantData={consultantData} consultantId={consultantId} />
            {/* <Posts /> */}
          </Card>

          <DefaultFooter content={footerRoutes} />
        </MKBox>
      </>
    );
  }
}

export default SingleConsultant;
