import MKBox from "components/MKBox";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import routes from "routes";
import privacyPolicyImg from "assets/images/10207836-removebg-preview (1).png";
export const PrivacyPolicy = () => {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-react",
          label: "free download",
          color: "default",
        }}
      />
      <MKBox pt={{ lg: "85px", md: "65px", xs: "65px" }}>
        <div className=" p-12">
          <div className=" text-center font-bold text-4xl">Privacy Policy</div>
          <div className=" flex justify-center">
            <img src={privacyPolicyImg} height={500} width={500} />
          </div>
          <div className=" mt-3">
            <div className=" font-bold text-2xl">Introduction</div>
            <div className=" mt-2">
              {" "}
              Welcome to AL-CNT SOLUTIONS LLP, operating as al-connect . We are committed to
              protecting and respecting your privacy. This Privacy Policy explains how we collect,
              use, disclose, and safeguard your information when you visit our website and use our
              services.
            </div>
          </div>
          <div className=" mt-3">
            <div className=" font-bold text-2xl">Company Information</div>
            <div className=" mt-2">
              AL-CNT SOLUTIONS LLP operates as al-connect, providing services to help students with
              college applications, profile building, and post-admit services by connecting them
              with mentors from top colleges.
            </div>
          </div>
          <div className=" mt-3">
            <div className=" font-bold text-2xl">Information we collect</div>
            <div className=" mt-2">
              We collect personal and professional information essential for college applications
              and related services. The types of information we may collect include:
              <ul className=" ml-8">
                <li>
                  Personal Information: Name, email address, phone number, and other contact
                  details.
                </li>
                <li>
                  Professional Information: Resume, academic records, work experience, and other
                  relevant details.
                </li>
                <li>
                  Cookies and Tracking Technologies: To enhance user experience and analyze website
                  traffic.
                </li>
              </ul>
            </div>
          </div>
          <div className=" mt-3">
            <div className=" font-bold text-2xl">How We Collect Information</div>
            <div className=" mt-2">
              We collect information through various methods, including:
              <ul className=" ml-8">
                <li>Directly from you via forms, resumes, and other documents.</li>
                <li>Automatically through cookies and tracking technologies.</li>
                <li>Via third-party services such as Google Forms.</li>
              </ul>
            </div>
          </div>
          <div className=" mt-3">
            <div className=" font-bold text-2xl"> How We Use Your Information</div>
            <div className=" mt-2">
              We use the information collected for the following purposes:
              <ul className=" ml-8">
                <li>To analyze and improve your profile for college applications.</li>
                <li>To connect you with appropriate mentors.</li>
                <li>
                  To assist with post-admit services such as banking, accommodation, and travel
                  arrangements.
                </li>
              </ul>
            </div>
          </div>
          <div className=" mt-3">
            <div className=" font-bold text-2xl"> Sharing Your Information</div>
            <div className=" mt-2">
              We may share your information with:
              <ul className=" ml-8">
                <li>Mentors who assist in the application process.</li>
                <li>
                  Third-party partners including banks, accommodation providers, and travel
                  agencies.
                </li>
                <li>Other entities as required by law or to protect our rights and interests.</li>
              </ul>
            </div>
          </div>
          <div className=" mt-3">
            <div className=" font-bold text-2xl">Data Retention</div>
            <div className=" mt-2">
              We retain your personal and professional information as long as necessary to fulfill
              the purposes outlined in this Privacy Policy unless a longer retention period is
              required or permitted by law. Users can request data deletion by contacting us at
              info@al-connect.in.
            </div>
          </div>
          <div className=" mt-3">
            <div className=" font-bold text-2xl">Data Security</div>
            <div className=" mt-2">
              We implement a variety of security measures to maintain the safety of your personal
              information, including encryption, secure servers, and access controls.
            </div>
          </div>
          <div className=" mt-3">
            <div className=" font-bold text-2xl">User Rights</div>
            <div className=" mt-2">
              You have the right to:
              <ul className=" ml-8">
                <li>Access the personal information we hold about you.</li>
                <li>Opt-out of data collection and processing.</li>
                <li>Manage your cookie preferences.</li>
              </ul>
              To exercise these rights, please contact us at info@al-connect.in.
            </div>
          </div>
          <div className=" mt-3">
            <div className=" font-bold text-2xl">Cookies and Tracking Technologies</div>
            <div className=" mt-2">
              We use cookies and similar tracking technologies to:
              <ul className=" ml-8">
                <li>Enhance your user experience.</li>
                <li>Analyze site traffic and usage patterns.</li>
                <li>Provide personalized content and advertisements.</li>
              </ul>
              You can manage your cookie preferences through your browser settings.
            </div>
          </div>
          <div className=" mt-3">
            <div className=" font-bold text-2xl">International Data Transfers</div>
            <div className=" mt-2">
              Your information may be transferred to and maintained on servers located outside your
              state, province, country, or other governmental jurisdiction where data protection
              laws may differ. We ensure that appropriate safeguards are in place to protect your
              data during international transfers.
            </div>
          </div>
          <div className=" mt-3">
            <div className=" font-bold text-2xl">Changes to This Privacy Policy</div>
            <div className=" mt-2">
              We may update this Privacy Policy from time to time. We will notify you of any changes
              by posting the new Privacy Policy on our website. You are advised to review this
              Privacy Policy periodically for any changes.
            </div>
          </div>
          <div className=" mt-3">
            <div className=" font-bold text-2xl">Contact Us</div>
            <div className=" mt-2">
              If you have any questions or concerns about this Privacy Policy, please contact us at:
              Email: info@al-connect.in
            </div>
          </div>
        </div>
      </MKBox>
    </>
  );
};
