/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import overWhelmed from "../../../assets/images/illustrations/Overwelmed by colloeges.svg";

// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
function Section8({ windowWidth }) {
  const faqs = [
    {
      q: `How does "Al-connect" differ from traditional study abroad consulting services?`,
      a: `Unlike traditional consulting, we connect you directly with mentors who have successfully navigated the college admission process you're targeting. Our platform is designed to offer personalized, pay-as-you-go sessions without long-term commitments, ensuring transparency, affordability, and genuine guidance based on real experiences.`,
    },

    {
      q: "How are mentors verified on your platform?",
      a: `We have a rigorous verification process that includes checking academic credentials, admission records, and personal interviews. This ensures our mentors are qualified and have genuinely attended the colleges and programs they claim to have experience with.`,
    },

    {
      q: `Can I change my mentor if I'm not satisfied with the guidance provided?`,
      a: `Absolutely. Our platform prioritizes your satisfaction and learning experience. If you feel the need to change your mentor for any reason, our support team can assist you in selecting another mentor who aligns better with your needs.`,
    },
    {
      q: `How do I pay for sessions, and are there any hidden fees?`,
      a: `Payments are made through our secure online platform at the time of booking. The price you see for each session or package is all-inclusive, with no hidden fees. Our transparent pricing policy ensures you know exactly what you're paying for.`,
    },
    {
      q: `Is there support available if I encounter issues during my mentorship?`,
      a: `Yes, our dedicated support team is always on hand to ensure your mentorship experience is smooth and productive. Whether it's scheduling issues, technical problems, or any other concerns, we're here to help every step of the way.`,
    },
    {
      q: `Does your platform offer any services after admission has been secured?`,
      a: "Indeed, we offer comprehensive post-admission support, encompassing assistance with visa applications, preparation for college interviews, guidance on securing both merit-based and third-party scholarships, accommodation arrangements, flight bookings, and facilitating loan procurement through our partner banks. We are committed to supporting you at every step of your journey.",
    },
  ];
  return (
    <MKBox component="section" py={3} className="section8">
      <Container>
        <Grid container xs={12} sm={12} md={12} xl={12} lg={12}>
          <MKTypography
            variant="text"
            style={{ color: "#2B3F5B", fontFamily: "Poppins", width: "100%" }}
            px={{ xs: 2, lg: 0 }}
            className="responsiveHeading"
            mt={1}
            mb={2}
            textAlign={{ lg: "left", xs: "center" }}
          >
            <b>Frequently Asked Questions</b>
          </MKTypography>
          <Accordion style={{ width: "100%" }}>
            {faqs.map((item, i) => (
              <AccordionItem uuid={i}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <span className="faqText">{item.q}</span>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p className="faqText">{item.a}</p>
                </AccordionItemPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Section8;
