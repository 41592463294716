import hat from "./../../../../assets/uni-logos/graduation-hat-1.svg";

export const ApplicationAssistanceCard = ({
  plan,
  noOfColleges,
  price,
  recommended = false,
  features,
  beforePrice,
  index,
}) => {
  const handlePayment = async () => {
    try {
      const res = await fetch(`https://al-connect-production.up.railway.app/phonepe/pay`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          amount: price,
        }),
      });
      if (res.ok) {
        const response = await res.json();
        window.location.href = response.data.instrumentResponse.redirectInfo.url;
      } else {
        const errorData = await res.text();
        console.log("Error text:", errorData);
        throw new Error(errorData);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className=" bg-white">
      <div className="applicationAssistanceCardContainer">
        {recommended && <div className="recommendedTag">Recommended</div>}
        <div
          className="applicationAssistanceCardHeader make-bold text-2xl flex justify-center items-center"
          style={
            index == 1
              ? { backgroundColor: "green", color: "white" }
              : index == 2
              ? { backgroundColor: "#ed4321", color: "white" }
              : {}
          }
        >
          {plan}
        </div>
        <div className=" mt-3 text-center price-container relative inline-block  font-bold">
          <div className="ml-6">
            <span className=" original-price-wrapper inline-block relative">
              <span className="original-price text-gray-500 text-4xl font-thin">
                <span className=" align-top  text-xs rupee">&#8377;</span>
                {beforePrice}
              </span>
              <div className="diagonal-line"></div>
            </span>
          </div>
          <div className=" flex flex-col ml-8">
            <span className=" text-start text-3xl text-blue-950  font-extrabold price-after mt-3">
              <span className="align-top  text-sm font-semibold">&#8377;</span>
              {price}
            </span>
          </div>
        </div>
        <div className=" flex  gap-1">
          <img src={hat} width={30} />{" "}
          <div className=" text-base  font-semibold">{noOfColleges} College plan</div>
        </div>
        <ul className="applicationFeatures">
          {features ? (
            features.map((feature) => <li>{feature}</li>)
          ) : (
            <>
              <li className="flex gap-1 items-center">
                <RightSvg />
                <span className="">Mentor of your choice</span>
              </li>
              <li className="flex gap-1 items-center">
                <RightSvg />
                <span>Profile Building</span>
              </li>
              <li className="flex gap-1 items-center">
                <RightSvg />
                <span>College Shortlisting</span>
              </li>
              <li className="flex gap-1 ">
                <div className=" mt-1">{index == 0 ? <WrongSvg /> : <RightSvg />}</div>
                <span>Unlimited SOP/ESSay edits</span>
              </li>
              <li className="flex gap-1 ">
                <div className=" mt-1">{index == 0 ? <WrongSvg /> : <RightSvg />}</div>
                <span>SOP/Essay & LOR Writing Guides</span>
              </li>
              <li className="flex gap-1 items-center">
                <RightSvg />
                <span>LOR review/edit</span>
              </li>
              <li className="flex gap-1 ">
                <div className=" mt-1">
                  <RightSvg />
                </div>
                <span>Resume Sample and Template</span>
              </li>
              <li className="flex gap-1">
                <div className=" mt-1">
                  <RightSvg />
                </div>
                <span>Free IELTS PREP material</span>
              </li>
              <li className="flex gap-1 items-center">
                <RightSvg />
                <span>Interview Preparation</span>
              </li>
              <li className="flex gap-1 ">
                <div className=" mt-1">{index == 0 ? <WrongSvg /> : <RightSvg />}</div>
                <span>Complete Scholarship assistance</span>
              </li>
              <li className="flex gap-1 items-center">
                <RightSvg />
                <span>Visa Prep</span>
              </li>
              <li className="flex gap-1 items-center">
                <RightSvg />
                <span>Post admit assistance</span>
              </li>
            </>
          )}
        </ul>
        <div
          style={
            index == 1
              ? { backgroundColor: "green", color: "white" }
              : index == 2
              ? { backgroundColor: "#ed4321", color: "white" }
              : {}
          }
          className="selectButton cursor-pointer"
          onClick={handlePayment}
        >
          Select
        </div>
      </div>
    </div>
  );
};
const RightSvg = () => {
  return (
    <div className=" max-w-4 min-w-4">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="15" height="15">
        <circle cx="12" cy="12" r="10" fill="#4CAF50" />
        <path fill="#FFF" d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
      </svg>
    </div>
  );
};
const WrongSvg = () => {
  return (
    <div className=" max-w-4 min-w-4">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="15" height="15">
        <circle cx="12" cy="12" r="10" fill="#F44336" />
        <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fill="#FFF">
          ✕
        </text>
      </svg>
    </div>
  );
};
