//slick carousel
import "./BookingPreference.css";
import "../../../../styles/commonStyles.css";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

function Faqs(props) {
  const faqs = props.faqs;
  return (
    <MKBox py={"48px"}>
      <MKTypography variant="h2" mb={"18px"} className=" text-center font-extrabold">
        Frequently Asked Questions <span className=" text-orange-600">(FAQS)</span>
      </MKTypography>
      {faqs.map((faq) => {
        return (
          <Accordion
            style={{ borderRadius: "24px", border: "none", boxShadow: "none", marginBottom: "8px" }}
          >
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon color="white" />}
              style={{
                backgroundColor: "#0C2343",
                color: "#ffffff",
                borderRadius: "8px",
                border: "none",
              }}
            >
              <MKTypography color="white">{faq.question}</MKTypography>
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#ffffff", color: "grey" }}>
              <MKTypography color="grey">{faq.answer}</MKTypography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </MKBox>
  );
}
export default Faqs;
