export const FileSVG = () => {
  return (
    <svg
      width="80"
      height="60"
      viewBox="0 0 159 126"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M63.395 67.5415L56.7388 63.602L57.068 61.4245C55.9252 60.1498 50.6477 60.5357 48.6875 60.7079L25.1959 62.7692C23.237 62.9415 22.86 63.9121 24.3605 64.926L30.574 69.1253C32.0745 70.1397 34.8924 70.7485 36.8352 70.4778L47.9323 68.9368L44.5933 82.8283C44.2244 84.3649 45.1063 84.7513 46.5541 83.6916L63.1857 71.8909C63.0671 69.9251 64.9757 68.4761 63.395 67.5415Z"
        fill="url(#paint0_linear_161_294)"
      />
      <path
        d="M156.802 91.3937C150.269 87.7732 13.0063 22.2817 13.0063 22.2817L2.07013 31.0742C2.07013 31.0742 1.51735 31.9306 2.26391 32.3484C7.16868 35.084 44.0865 52.1807 65.2099 73.0081C87.8227 95.3056 95.8006 97.2414 118.005 124.944C118.425 125.467 119.652 125.789 121.019 125.165C125.863 122.953 160.914 100.15 156.802 91.3937Z"
        fill="url(#paint1_linear_161_294)"
      />
      <path
        opacity="0.2"
        d="M127.117 106.285C127.117 106.285 127.047 121.209 127.117 121.179C127.375 121.147 127.62 121.111 127.874 121.077C139.403 113.59 160.008 98.2219 156.802 91.3938H152.361L127.117 106.285Z"
        fill="url(#paint2_linear_161_294)"
      />
      <path
        d="M1.87321 18.6628C1.58502 18.4255 7.94626 1.95152 25.8195 0.492188C25.8195 0.492188 94.6224 57.4038 156.802 91.3938C156.802 91.3938 138.552 98.5709 127.481 120.737C127.203 121.29 126.866 121.417 126.391 120.935C98.049 92.1001 81.3415 81.1814 64.5801 68.3963C47.5379 55.3967 2.16078 18.9015 1.87321 18.6628Z"
        fill="url(#paint3_linear_161_294)"
      />
      <path
        opacity="0.2"
        d="M52.0402 58.6471C56.9251 62.4871 61.284 65.8827 64.5801 68.3973C64.9875 68.7084 65.395 69.017 65.8024 69.3266C69.2222 62.4965 77.9119 47.7673 89.0083 48.3835L88.95 48.3412C83.0601 44.1867 77.2234 39.9851 71.4407 35.7372C68.0011 34.5146 55.9382 42.7061 52.0402 58.6471Z"
        fill="#0C2343"
      />
      <path
        d="M50.4235 60.5499L63.1858 71.8909C63.1858 71.8909 73.8232 47.0458 88.2488 47.8456C88.5084 47.7083 74.7344 37.834 70.7494 35.0842C67.7117 32.9894 53.6315 42.1977 50.4235 60.5499Z"
        fill="url(#paint4_linear_161_294)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_161_294"
          x1="45.7082"
          y1="59.0507"
          x2="41.4683"
          y2="83.6623"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FD5523" />
          <stop offset="1" stopColor="#D95F3B" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_161_294"
          x1="1.87324"
          y1="73.895"
          x2="157.137"
          y2="73.895"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4A83D3" />
          <stop offset="1" stopColor="#326BBB" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_161_294"
          x1="127.085"
          y1="106.286"
          x2="157.137"
          y2="106.286"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#365F99" />
          <stop offset="1" stopColor="#234A81" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_161_294"
          x1="87.0643"
          y1="48.2711"
          x2="72.1162"
          y2="68.6914"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E3E3E3" />
          <stop offset="1" stopColor="#E2E2E2" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_161_294"
          x1="74.4611"
          y1="43.6089"
          x2="65.2828"
          y2="64.1349"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FD5523" />
          <stop offset="1" stopColor="#D95F3B" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export const Plane = () => {
  return (
    <svg
      width="80"
      height="60"
      viewBox="0 0 121 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M102.271 74.0588C101.953 74.3556 101.575 74.591 101.159 74.7517C100.743 74.9124 100.297 74.9951 99.8467 74.9951C99.3962 74.9951 98.9501 74.9124 98.5341 74.7517C98.1181 74.591 97.7404 74.3556 97.4227 74.0588L92.5748 69.5564C92.2553 69.2613 92.0017 68.9106 91.8287 68.5242C91.6557 68.1379 91.5667 67.7236 91.5667 67.3051C91.5667 66.8867 91.6557 66.4724 91.8287 66.0861C92.0017 65.6997 92.2553 65.3489 92.5748 65.0539L102.271 56.049C102.589 55.753 102.967 55.5182 103.383 55.358C103.799 55.1978 104.244 55.1153 104.695 55.1153C105.145 55.1153 105.591 55.1978 106.007 55.358C106.423 55.5182 106.8 55.753 107.119 56.049L111.967 60.5514C112.286 60.8465 112.54 61.1972 112.713 61.5836C112.886 61.9699 112.975 62.3842 112.975 62.8027C112.975 63.2211 112.886 63.6354 112.713 64.0217C112.54 64.4081 112.286 64.7589 111.967 65.0539L102.271 74.0588Z"
        fill="#FD5523"
      />
      <path
        d="M50.8424 26.2952C50.5248 26.5919 50.1471 26.8274 49.7311 26.988C49.3151 27.1487 48.869 27.2314 48.4185 27.2314C47.9679 27.2314 47.5218 27.1487 47.1058 26.988C46.6898 26.8274 46.3122 26.5919 45.9945 26.2952L41.1465 21.7927C40.827 21.4977 40.5735 21.1469 40.4005 20.7606C40.2275 20.3742 40.1384 19.9599 40.1384 19.5415C40.1384 19.123 40.2275 18.7087 40.4005 18.3224C40.5735 17.936 40.827 17.5853 41.1465 17.2902L50.8424 8.28528C51.1606 7.98932 51.5384 7.75452 51.9543 7.59432C52.3703 7.43412 52.8161 7.35167 53.2664 7.35167C53.7167 7.35167 54.1626 7.43412 54.5785 7.59432C54.9944 7.75452 55.3723 7.98932 55.6904 8.28528L60.5384 12.7878C60.8578 13.0828 61.1114 13.4336 61.2844 13.8199C61.4574 14.2063 61.5465 14.6206 61.5465 15.039C61.5465 15.4574 61.4574 15.8717 61.2844 16.2581C61.1114 16.6444 60.8578 16.9952 60.5384 17.2902L50.8424 26.2952Z"
        fill="#FD5523"
      />
      <path
        d="M6.27141 68.2373C13.1285 68.2373 43.9853 71.4215 43.9853 71.4215C43.9853 71.4215 47.4139 100.079 47.4139 106.448C47.4139 112.816 40.5568 112.816 37.1283 109.632C33.6997 106.448 23.4141 90.5268 23.4141 90.5268C23.4141 90.5268 6.27141 80.9742 2.84287 77.79C-0.585668 74.6058 -0.585668 68.2373 6.27141 68.2373Z"
        fill="#EE7855"
      />
      <path
        d="M13.1286 17.4143C23.4142 17.2901 98.8421 20.4743 98.8421 20.4743C98.8421 20.4743 102.01 90.527 102.14 100.08C102.271 109.632 92.6707 112.823 88.8993 100.083C85.1279 87.3428 71.4137 45.948 71.4137 45.948C71.4137 45.948 23.5685 31.8866 13.1114 29.7022C-0.585586 26.8428 2.84295 17.5385 13.1286 17.4143Z"
        fill="#EE7855"
      />
      <path
        d="M91.9849 7.73711C98.842 1.36868 115.985 -1.81553 119.413 1.36868C122.842 4.55289 119.413 20.4739 112.556 26.8424C105.699 33.2108 47.4139 84.1582 47.4139 84.1582C47.4139 84.1582 25.1284 98.4871 19.9856 93.7108C14.8428 88.9345 30.2712 68.2371 30.2712 68.2371C30.2712 68.2371 85.1278 14.1055 91.9849 7.73711Z"
        fill="#DDD0CC"
      />
      <path
        d="M47.414 68.2377C47.414 68.2377 49.1283 69.8298 33.6999 84.1588C18.2714 98.4877 16.5572 96.8956 16.5572 96.8956C16.5572 96.8956 14.8429 95.3035 30.2713 80.9746C45.6997 66.6456 47.414 68.2377 47.414 68.2377Z"
        fill="#FD5523"
      />
      <path
        d="M98.8419 10.921C101.57 10.921 104.186 11.9274 106.115 13.7189C108.044 15.5104 109.128 17.9401 109.128 20.4736H111.888C112.278 19.4706 112.556 18.4198 112.556 17.2894C112.556 14.7559 111.472 12.3262 109.543 10.5347C107.615 8.74323 104.998 7.73679 102.27 7.73679C101.053 7.73679 99.9185 7.99471 98.8419 8.35771V10.921Z"
        fill="#FD5523"
      />
    </svg>
  );
};
