/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";
import "./service";
// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";
import Presentation from "layouts/pages/presentation";

// Material Kit 2 React routes
import routes from "routes";
import { QueryClient, QueryClientProvider } from "react-query";
import "./index.css";
import { ResponseForm } from "pages/FormResponse";
import { SpeakWithExperts } from "pages/Speak-with-Experts/SpeakWithExperts";
import { Toaster } from "react-hot-toast";
import { PrivacyPolicy } from "pages/Privacy-Policy/PrivacyPolicy";
import { RefundPolicy } from "pages/RefundPolicy/RefundPolicy";
import { StartACollegeResearch } from "pages/Start-a-College-Research";
import { MoreUniversities } from "pages/MoreUniversities/MoreUniversities";
import { BookingPage } from "pages/LandingPages/BookingPage/BookingPage";
import { TermsAndConditions } from "pages/LandingPages/TandC/TermsAndConditions";
import { Confirmation } from "pages/LandingPages/BookingPage/Confirmation";
import { PaymentSuccess } from "pages/LandingPages/SingleConsultant/sections/ValidatePayment";
import { PaymentFailure } from "pages/LandingPages/SingleConsultant/sections/ValidatePayment";
export default function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: 0, // Disable caching for all queries
      },
    },
  });
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Toaster />
        <Routes>
          {getRoutes(routes)}
          <Route path="/" element={<Presentation />} />
          <Route path="/consultant-form" element={<Presentation />} />
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/onboarding" element={<ResponseForm />} />
          <Route path="/speak-with-experts" element={<SpeakWithExperts />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/start-a-college-research" element={<StartACollegeResearch />} />
          <Route path="/more-universities" element={<MoreUniversities />} />
          <Route path="/bookingpage" element={<BookingPage />} />
          <Route path="/terms-conditions" element={<TermsAndConditions />} />
          <Route path="/confirmation" element={<Confirmation />} />
          <Route path="payment-success" element={<PaymentSuccess />} />
          <Route path="payment-failed" element={<PaymentFailure />} />
        </Routes>
      </ThemeProvider>
    </QueryClientProvider>
  );
}
