/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import overWhelmed from "../../../assets/images/illustrations/Overwelmed by colloeges.svg";

// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import quote from "assets/svgs/quote.svg";
import ryanImg from "assets/images/ryanImg.png";
import uniLogo from "assets/images/Duke-University-Logo.png";
// import GuaranteeTag from "assets/images/Group 24023.png";
import { Insights } from "./Insights";
import adityaImg from "assets/images/aditya.jpg";
import seshaImg from "assets/images/sesha.jpg";
import blessyImg from "assets/images/blessy.jpg";
import twinkleImg from "assets/images/twinkle.jpg";
import justinImg from "assets/images/justin.jpg";
import iitroorkeeLogo from "assets/images/IITroorkee.png";
import tudresdenLogo from "assets/images/tudresden.jpeg";
import imperialClgLogo from "assets/images/imperialclg.png";
import erasmusmundusLogo from "assets/images/erasmusmundus.jpg";
import universityLongonLogo from "assets/images/londoncldLogo.png";
import guaranteeFlag from "assets/images/garanteeFlag.png";
import guaranteeTag from "assets/images/Group 24021.png";
const sliderContent = [
  {
    name: "Aditya Pratap Singh",
    degree: "DAAAD Fellowship Tu Dresden",
    college: "",
    reviews:
      "I wanted to take a moment to express my heartfelt gratitude to Mr. Kevin for the invaluable assistance they provided me with in crafting my Statement of Purpose (SOP) for the DAAD fellowship application to Germany. I got the fellowship in Technical University Dresden, Germany and I am thankful to Al-connect for their constant support. Although it was a bit expensive, but the services provided outweighed the cost i paid",
    image: adityaImg,
    uniLogo: tudresdenLogo,
  },
  {
    name: "Prabhat",
    degree: "",
    college: "",
    reviews:
      "I am grateful for the help Kevin and his team has provided me for my admission application process. Even starting with the process was daunting and too time consuming, adding a lot of stress to an already tense process.With the seasoned experts guiding me through all the tasks, it became a rather smooth sailing. I got fellowships from various European Universities. Forever proud to take the call and receive their service.",
    image: ryanImg,
    uniLogo: uniLogo,
  },
  {
    name: "Blessy",
    degree: "Ms Finance",
    college: "Imperial college london",
    reviews:
      "I am really grateful to Mr. Kevin Rajan Singh and his team at Al-connect for getting me into one of my dream universities with proper guidance and support. I have a mathematics background and wanted to pursue a finance course. Due to this, I needed to work more on my SOP to make it through the selection process. Mr. Kevin with his feedbacks and advice helped me get selected for MS Finance. I'm still thankful and would connect with him again for any future purposes.",
    image: blessyImg,
    uniLogo: imperialClgLogo,
  },
  {
    name: "Justin",
    degree: " Ms Energy engineering",
    college: "Erasmus Mundus",
    reviews:
      "I extend my heartfelt gratitude to Kevin and team for their exceptional professionalism, dedication, and commitment. Their services proved to be truly invaluable, and I would wholeheartedly recommend them. With their guidance and expertise, I successfully navigated the challenges of my applications, crafting a compelling SOP that authentically portrayed my aspirations. Thanks to their assistance, I secured the scholarship to my dream university in France.",
    image: justinImg,
    uniLogo: erasmusmundusLogo,
  },
  {
    name: "Twinkle",
    degree: "MS in Technology Management",
    college: "University college london",
    reviews:
      "I'm a master's student at University College London and it was all made possible by the guidance of Kevin. He helped me in shortlisting UK universities and applying to them. He is a knowledgeable and kind-hearted person who addressed all my queries with patience. If you also need help in applying to the top universities, don't hesitate to connect with team Al-connect.",
    image: twinkleImg,
    uniLogo: universityLongonLogo,
  },
  {
    name: "Sesha",
    degree: "Tu Dresden- DAAD Fellowship",
    college: "IIT Roorkee",
    reviews:
      "I was very stressed about my DAAD application. But, team Al-connect calmed me down and gave personalized guidance. They helped me navigate the whole process and polished my SOP which helped me get into my dream university in Germany with a DAAD fellowship! Highly recommended.",
    image: seshaImg,
    uniLogo: iitroorkeeLogo,
  },
];

const SliderDiv = () => {
  return (
    <div className="sliderDiv">
      <img src={quote}></img>
      <p className="carouselText">
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s
      </p>
      <div className="bottomOuterCarousel">
        <div className="bottomInnerCarousel">
          <img width={70} style={{ height: "fit-content" }} src={ryanImg}></img>
          <div className="carouselMeta">
            <p style={{ color: "#FD5523" }}>Rayan</p>
            <p style={{ fontSize: 15 }}>
              Masters in<br></br>Business Administration
            </p>
          </div>
        </div>
        <img width={69} height={"100%"} src={uniLogo}></img>
      </div>
    </div>
  );
};
function NextArrow(props) {
  return <div className="arrowHolder"></div>;
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "green" }}
      onClick={onClick}
    />
  );
}
function Section9({ windowWidth }) {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <MKBox component="section" py={3} className="section9">
      <Container>
        <Grid
          style={{ textAlign: "center", justifyContent: "center" }}
          container
          xs={12}
          sm={12}
          md={12}
          xl={12}
          lg={12}
        >
          <MKTypography
            variant="text"
            style={{ color: "#2B3F5B", fontFamily: "Poppins", width: "100%" }}
            px={{ xs: 2, lg: 0 }}
            className="responsiveHeading"
            mt={1}
            mb={2}
            textAlign={{ lg: "center", xs: "center" }}
          >
            <b>
              See What <span style={{ color: "#FD5523" }}>Happiness</span> Looks Like!
            </b>
          </MKTypography>
          <div className={"slider"}>
            <Slider {...settings}>
              {sliderContent.map((slider, index) => (
                <div key={index} className="sliderDiv border-8">
                  <img className=" h-[5%]" width={35} height={35} src={quote}></img>
                  <p className="carouselText h-[60%]">{slider.reviews}</p>
                  <div className="bottomOuterCarousel h-[35%]">
                    <div className="bottomInnerCarousel">
                      <img
                        className=" rounded-full max-h-32"
                        width={70}
                        style={{ height: "fit-content" }}
                        src={slider.image}
                        height={70}
                      />
                      <div className="carouselMeta text-sm sm:text-base">
                        <p style={{ color: "#FD5523" }}>{slider.name}</p>
                        <p style={{ fontSize: 15 }}>
                          {slider.degree}
                          <br></br>
                          {slider.college}
                        </p>
                      </div>
                    </div>
                    <img
                      width={69}
                      height={"100%"}
                      className=" max-h-28"
                      src={slider.uniLogo}
                    ></img>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          {/* <button
            style={{
              marginTop: "36px",
            }}
            className="primary-btn"
          >
            Read More Reviews
          </button> */}

          <div className=" flex justify-center flex-col gap-5">
            <b className="text-xl lg:text-4xl">
              We Offer an <span style={{ color: "#FD5523" }}>Insurance </span> on your Investment
            </b>
            <div className="flex justify-center items-center mt-6 mb-2">
              <div className="relative flex items-center">
                <img src={guaranteeTag} className="absolute" alt="" height={150} width={150} />
                <img
                  src={guaranteeFlag}
                  alt="try us risk free"
                  className="max-h-24 ml-8"
                  height={30}
                  width={350}
                />
                <span className="absolute ml-2 text-xl text-slate-200 left-40 font-bold">
                  TRY US RISK FREE
                </span>
              </div>
            </div>
          </div>
          <Insights />
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Section9;
